section.rotary-block {
    .rotary-heading {
        width: 90%;
        margin: 0 auto;
        padding: 3rem 0 0;

        h2 {
            font-size: 4.25rem;
            text-align: center;
            margin: 0;
        }
        .message {
            font-size: 1.25rem;
            text-align: left;
            margin: 1.5rem auto 0;
            width: 65%;

            a {
                color: $red;
                text-decoration: none;
                font-style: initial;
                
                &:hover {
                    color: $dark-red;
                }
            }
        }
    }
    .rotary-inner {
        .rotary-wheel {
            .wheel {
                border-radius: 100%;
                border: 3px solid $dark-gray;
                width: 41.5vw;
                height: 41.5vw;
                margin: 8vw 8vw;
                position: relative;
                .rotary-icon {
                    position: absolute;
                    margin-left: -5.35vw;
                    border-radius: 100%;
                    img {
                        padding: 2.5vw;
                        border-radius: 100%;
                        border: 3px solid $dark-gray;
                        width: 5.7vw;
                        height: 5.7vw;
                        background-color: $white;
                    }

                    &:hover {
                        img {
                            border-color: $yellow;
                        }
                    }

                    &.active {
                        img {
                            border-color: $red;
                        }
                    }

                    &:first-of-type {
                        top: -5.35vw;
                        left: 50%;
                    }

                    &:nth-of-type(2) {
                        top: 20%;
                        left: 98%;
                    }

                    &:nth-of-type(3) {
                        top: 75%;
                        left: 80%;
                    }

                    &:nth-of-type(4) {
                        top: 75%;
                        left: 20%;
                    }

                    &:nth-of-type(5) {
                        top: 20%;
                        left: 2%;
                    }
                    
                }
            }
        }

        .rotary-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .info-block {
                &.active {
                    display: block;
                }
    
                display: none;

                h3 {
                    font-size: 4.25rem;
                    font-family: $merriweather;
                    margin: 0 0 .5rem;
                }
                .message {
                    font-size: 1.375rem;
                }
            }
            .rotary-controls {
                margin: 2rem 0;

                i {
                    font-size: 3.375rem;
                    color: $red;
                    cursor: pointer;

                    &:first-of-type {
                        margin: 0 2rem 0 0;
                    }
                    &:hover {
                        color: $dark-red;
                    }
                }
            }
        }
    }

    @include breakpoint(laptop, max) {
        .rotary-inner {
            .rotary-info {
                .info-block {
                    h3 {
                        font-size: 3.5rem;
                    }
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .rotary-heading {
            h2 {
                font-size: 3.5rem;
            }
            .message {
                font-size: 1rem;
                width: 100%;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .rotary-heading {
            width: 90%;
            margin: 1rem auto 0;
            padding: 2rem 0 0;

            h2 {
                font-size: 1.5rem;
            }
            .message {
                font-size: 1rem;
                margin-bottom: 1rem;
            }
        }
        .rotary-inner {
            .rotary-wheel {
                flex-basis: 100%;
                display: flex;
                justify-content: center;
                margin: 12vw 0 20vw;

                .wheel {
                    width: 71.5vw;
                    height: 71.5vw;
                    margin: 0;

                    .rotary-icon {
                        
                        margin-left: -11.8vw;
                        img {
                            padding: 5vw;
                            width: 12vw;
                            height: 12vw;
                        }

                        &:first-of-type {
                            top: -7.8vw;
                            left: 50%;
                        }
    
                        &:nth-of-type(2) {
                            top: 18%;
                            left: 94%;
                        }
    
                        &:nth-of-type(3) {
                            top: 75%;
                            left: 80%;
                        }
    
                        &:nth-of-type(4) {
                            top: 75%;
                            left: 20%;
                        }
    
                        &:nth-of-type(5) {
                            top: 18%;
                            left: 6%;
                        }
                    }
                }
            }
            .rotary-info {
                margin: 2rem 5% 0;
                flex-basis: 100%;
                order: -1;

                .info-wrapper {
                    .info-block {
                        h3 {
                            font-size: 1.5rem;
                        }
                        .message {
                            font-size: 1rem;
                        }
                    }
                }

                .rotary-controls {
                    margin: 2rem 0 0;
                    i {
                        font-size: 2.25rem;
                    }
                }
                
                
            }
        }
    }
}