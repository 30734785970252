.story-form-modal, .system-form-modal {
  input, select, textarea {
    width: 100%;
  }
  h2 {
    margin: 0;
    font-size: 3rem;
  }
}

.subscribe-form-modal {
  h3 {
    margin: 0;
    font-size: 3rem;
  }
  .form-success {
    color: $black;
  }
  .form-note {
    margin: 1em 0;
  }
}

form {
  input, select, textarea {
    display: block;
    width: 89%;
    margin: 2.5rem 0;
  
    font-size: 1.25rem;
    padding: 1.125em 5%;
    border: 1px solid $light-gray;
  
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;
  
    &::placeholder {
        font-size: 1.25rem;
        color: $medium-gray;
    }
  }
  
  select {
    border-radius: 0 !important;
    height: 72px;
    background-color: $white;
    background-image: url('/img/field.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    color: $medium-gray;
    padding: 0 5%;
  
    -webkit-appearance: none;
    -moz-appearance: none;
  
    &::-ms-expand {
        display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
    }
  }
  
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
  
    & ~ .checkbox {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: solid 3px $light-gray;
      border-radius: 4px;
    }
  
    &:checked {
      & ~ .checkbox {
        background-color: $red;
        border-color: $red;
      }
    }
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  
    &:focus + label {
      outline: 1px dotted $black;
      outline: -webkit-focus-ring-color auto 5px;
    }
  
    & + label {
      padding: .5em 2em;
      font-size: 1.25rem;
      color: $white;
      background-color: $red;
      border-radius: 30px;
      display: inline-block;
      cursor: pointer;

      &:hover {
        background-color: $dark-red;
      }
    }
  }
}

.file-previews {
  display: inline-block;
  margin: 1em 0;
  cursor: auto;
}

.file-preview {
  display: inline-block;
  margin: .5em;
  padding: .5em 1em;
  color: $white;
  background-color: $red;
  border-radius: 15px;
  cursor: auto;
}

.file-preview-remove-btn {
  margin-left: 8px;
  color: $white;
  cursor: pointer;
}

.form-column {
  position: relative;

  &.checkbox {
    margin-bottom: 2em;
    
    label {
      position: relative;
      padding-left: 40px;
      padding-top: 2px;
      cursor: pointer;
      font-family: $libre;
      font-size: 1.25rem;
      color: $medium-gray;
    }
  }
}

.form-field {
  &.ff-has-errors {
    border-color: $red;
  }
}

.form-note {
  font-size: 1.25rem;
}

.form-instructions {
  font-family: $libre;
  margin: .25em 0;
}

.form-success {
  color: $white;
}

.form-error {
  color: $red;
}

.field-error {
  font-family: $libre;
  color: $red;
}

p.ff-form-success {
  font-family: $libre;
  font-size: 1.5rem;
  font-weight: bold;
}

p.ff-form-error {
  font-family: $libre;
  font-size: 1.5rem;
  font-weight: bold;
  color: $red;
}

div.ff-form-errors {
  p {
    margin: 1em 0;
    font-family: $libre;
    color: $red;
  }
}

ul.ff-errors {
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  margin: 2px 0 0 0;
  padding: 0;

  li {
    font-family: $libre;
    color: $red;
  }
}

#mc_embed_signup form{
  margin: 0 !important;
}

#mc_embed_signup_scroll{
  
  h2{
    color: #fff;
  }
  label{
    color: #fff;
  } 
  .mc-field-group{
    height: 80px;
    margin: 38px 0;
    input{
      padding: 1.125em 5% !important;
      width: 93% !important;
    }
  }
  .indicates-required{
    color: #fff;
  }
  input{
    margin: 0 !important;
   
  }

  .sub-button{
    
      
    padding: 1em 2em;
    margin: 18px !important;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 30px;
    text-decoration: none;
    display: inline-block;
    
    cursor: pointer;
    font-family: $oswald;
    transition: all .2s ease;
    border: 0;
    background-color: $red;
    color: $white !important;
    &:hover {
        background-color: $dark-red;
    }
  }}

  #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error{
    background-color: transparent !important;
  }