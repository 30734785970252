section.picture-grid {
	&.bg-w {
		background-color: $white;
	}

	&.bg-g {
		background-color: $ultra-light-gray;
	}
	.grid-inner {
		width: 90%;
		margin: 0 auto;
		padding: 3rem 0;

		h2 {
			font-size: 4.25rem;
			text-align: center;
			margin: 0;
		}
		.message {
			font-size: 1.25rem;
			text-align: center;
			margin: 1.5rem 0;
		}
		.pics {
			.pic-block {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 5rem;

				a {
					border-radius: 100%;
					.picture-wrap {
						background-color: $extra-light-gray;
						width: 19.5vw;
						height: 19.5vw;
						border-radius: 100%;
						position: relative;

						img {
							border-radius: 100%;
							background-color: $extra-light-gray;
							width: 100%;
							height: 100%;

							&.add {
								width: 60%;
								height: auto;
								padding: 19%;
								border: 3px solid $dark-gray;
								background-color: $white;
								margin: auto auto;
							}
						}

						svg {
							font-size: 1.875rem;
							width: 1.875rem;
							text-align: center;
							position: absolute;
							bottom: 0;
							right: 0;
							padding: 1.5rem;
							border-radius: 100%;
							color: $white;
							transition: all 0.2s ease;

							&.play {
								background: $red;
							}

							&.file {
								background-color: $black;
							}
						}
					}

					&:hover {
						.picture-wrap {
							i {
								background-color: $yellow;
							}
						}
					}
				}

				.name {
					font-size: 2.25rem;
					margin: 0.5rem;
					text-align: center;
				}

				.title {
					font-size: 1.0625rem;
					text-align: center;
					font-family: $merriweather;
					width: 75%;
				}
			}
		}
	}

	@include breakpoint(tablet, max) {
		.grid-inner {
			h2 {
				font-size: 3.5rem;
			}
			.message {
				font-size: 1rem;
			}
			.pics {
				.pic-block {
					a {
						border-radius: 100%;
						.picture-wrap {
							i {
								font-size: 1.25rem;
								width: 1.25rem;
								text-align: center;
								padding: 1rem;
							}
						}
					}

					.name {
						margin: 0.5rem 2rem;
						font-weight: 400;
					}
				}
			}
		}
	}

	@include breakpoint(mobile, max) {
		.grid-inner {
			width: 90%;
			margin: 3rem auto;

			h2 {
				font-size: 1.5rem;
			}
			.message {
				font-size: 1rem;
				margin-bottom: 4rem;
			}
			.pics {
				.pic-block {
					flex-basis: 100%;

					a {
						.picture-wrap {
							width: 72.5vw;
							height: 72.5vw;

							img {
								&.add {
									width: 60%;
									padding: 19%;
									border: 3px solid $dark-gray;
									background-color: $white;
									margin: auto auto;
								}
							}

							i {
								font-size: 5.3vw;
								width: 5.3vw;
								text-align: center;
								position: absolute;
								bottom: 0;
								right: 0;
								padding: 6.5vw;
								border-radius: 100%;
								color: $white;

								&.play {
									background: $red;
								}

								&.file {
									background-color: $black;
								}
							}
						}

						&:hover {
							.picture-wrap {
								i {
									background-color: $yellow;
								}
							}
						}
					}

					.name {
						font-size: 2.25rem;
						margin: 0.5rem;
						text-align: center;
					}

					.title {
						font-size: 1.0625rem;
						text-align: center;
						font-family: $merriweather;
						width: 75%;
					}
				}
			}
		}
	}
}
