footer {
	.footer-wrap {
		.footer-top {
			background-color: $dark-blue-gray;
			.footer-inner {
				width: 90%;
				margin: 0 auto;
				padding: 4em 0;

				.feature-links-col {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.footer-block {
						padding-bottom: 2rem;
						h3 {
							font-family: $libre;
							font-size: 1.875rem;
							color: $white;
							font-weight: 900;
							margin: 0 0 0.75rem;
							a {
								font-family: $libre;
								font-size: 1.875rem;
								color: $white;
								text-decoration: none;
								font-weight: 900;
								transition: color 0.2s ease;

								&:hover {
									color: $red;
								}
							}
						}
						.message {
							font-family: $merriweather;
							color: $blue-gray;
							width: 75%;
							font-style: italic;
							font-size: 0.75rem;
						}
					}
				}

				.network-links-col {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					.footer-block {
						margin-left: 3em;
						h3 {
							font-family: $libre;
							font-size: 1rem;
							color: $yellow;
							font-weight: 600;
							margin: 0;
							a {
								font-family: $libre;
								font-size: 1rem;
								color: $yellow;
								text-decoration: none;
								font-weight: 600;
								transition: color 0.2s ease;
								display: flex;
								align-items: center;

								i {
									color: $white;
									padding-left: 0.5em;
									font-size: 0.75em;
								}
							}

							a[href] {
								&:hover {
									color: $white;
								}
							}
						}
						.message {
							font-family: $merriweather;
							color: $white;
							width: 75%;
							font-style: italic;
							font-size: 0.75rem;
						}
						.footer-social-links {
							margin-top: 1em;
							a {
								i {
									transition: all 0.3s ease;
								}
								&:hover {
									svg {
										&.yt,
										&.fb,
										&.tw,
										&.ig {
											background-color: transparent;
										}
									}
								}

								svg {
									path {
										fill: white !important;
									}
									color: $white;
									border-radius: 12px;
									margin-right: 0.5rem;
									height: 34px;
									width: 34px;
									position: relative;
									padding: 0.5rem;

									&:before {
										position: absolute;
										top: 50%;
										right: 50%;
									}

									&.fb {
										background-color: $fb;
										border: 2px solid $fb;
										&:before {
											margin: -9px -8px 0 0;
										}
									}
									&.tw {
										background-color: $tw;
										border: 2px solid $tw;
										&:before {
											margin: -9px -8px 0 0;
										}
									}
									&.yt {
										background-color: $yt;
										border: 2px solid $yt;
										&:before {
											margin: -9.25px -9.5px 0 0;
										}
									}
									&.ig {
										background-color: $ig;
										border: 2px solid $ig;
										&:before {
											margin: -9px -8px 0 0;
										}
									}
								}
							}
						}
					}
				}

				.form-col {
					h3 {
						font-family: $libre;
						font-size: 1.875rem;
						color: $white;
						font-weight: 900;
						margin: 0;
						a {
							font-family: $libre;
							font-size: 1.875rem;
							color: $white;
							text-decoration: none;
							font-weight: 900;
						}
					}
					.message {
						font-family: $merriweather;
						color: $white;
						width: 75%;
						font-style: italic;
						font-size: 0.75rem;
					}
					input,
					button {
						display: block;
						outline: 0;
						border: 0;
						margin: 2rem 0;
					}
					input {
						width: 80%;
						font-size: 1.125rem;
						padding: 0.75em;

						&::placeholder {
							font-size: 1.125rem;
							color: $medium-gray;
						}
					}
				}

				@include breakpoint(tablet, max) {
					.feature-links-col {
						flex-basis: 50%;
					}
					.network-links-col {
						flex-basis: 50%;
					}
					.form-col {
						flex-basis: 75%;
						margin-top: 4em;
					}
				}

				@include breakpoint(mobile, max) {
					.feature-links-col {
						flex-basis: 100%;
						.footer-block {
							h3 {
								font-size: 1.125rem;
								a {
									font-size: 1.125rem;
								}
							}
							.message {
								width: 100%;
							}
						}
					}
					.network-links-col {
						flex-basis: 100%;
						justify-content: space-between;
						flex-direction: row;
						flex-wrap: wrap;
						.footer-block {
							margin: 1rem 0;
							h3 {
								font-size: 0.8rem;
								a {
									font-size: 0.8rem;
								}
							}

							&:nth-child(odd) {
								flex-basis: 35%;
							}

							&:nth-child(even) {
								flex-basis: 65%;
							}

							.footer-social-links {
								a {
									i {
										margin-right: 0.25rem;
									}
								}
							}
						}
					}
					.form-col {
						flex-basis: 100%;
						margin-top: 4em;

						button {
							width: 100%;
						}
						input {
							width: 90%;
							padding: 0.75rem 5%;
						}
					}
				}
			}
		}

		.footer-bottom {
			.footer-inner {
				width: 90%;
				margin: 1.5rem auto;
				align-items: center;
				.fb-left {
					text-align: right;
					color: $medium-gray;
					font-size: 12px;
					font-family: $libre;
					line-height: 24px;

					.footer-links {
						padding-top: 2em;
					}
					.footer-links > a {
						font-size: 12px;
						font-family: $libre;
						line-height: 24px;
						text-decoration: none;
						padding-left: 2em;
						transition: color 0.2s ease;

						&:hover {
							color: $light-gray;
						}
					}
				}

				@include breakpoint(tablet, max) {
					.fb-left {
						flex-basis: 100%;
						margin-top: 2rem;
						text-align: center;
					}
					.fb-right {
						flex-basis: 100%;
						display: flex;
						justify-content: center;
						img {
							width: 80%;
						}
					}
				}

				@include breakpoint(mobile, max) {
					.fb-left {
						flex-basis: 100%;
						margin-top: 2rem;
						text-align: center;
						font-size: 10px;
					}
					.fb-right {
						flex-basis: 100%;

						img {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

@include breakpoint(mobile, max) {
	.cc-revoke.cc-bottom {
		display: none;
	}
}
