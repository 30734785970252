section.design-block {
    .design-inner {
        width: 60%;
        margin: 0 auto;
        text-align: center;

        @include breakpoint(tablet, max) {
           width: 90%;
        }

        h2 {
            font-size: 4.25rem;
        }

        .message {
            font-size: 1.25rem;
            font-weight: 400;
            width: 90%;
        }

        .network-selector {
            margin: 3rem 0;
            justify-content: space-between;

            @include breakpoint(tablet, max) {
                margin: 2rem 0;
            }

             .network-icon {
                position: relative;
                 cursor: pointer;
                 max-width: 170px;
                 width: 12vw;
                 height: 12vw;
                 max-height: 170px;
                 border: 10px solid transparent;
                 border-radius: 100%;
                 background-color: transparent;
                 transition: all .2s ease;

                 img {
                     width: 100%;
                 }

                 &:hover {
                     background-color: $charcoal;
                     border: 10px solid $charcoal;
                 }

                 &.active {
                    border: 10px solid $charcoal;

                    img {
                       filter: brightness(0) invert(1);
                    }

                    &[data-canvas-selector="fb"] {
                        background-color: $fb;
                    }
                    &[data-canvas-selector="tw"] {
                        background-color: $tw;
                    }

                    &[data-canvas-selector="pin"] {
                        background-color: $pin;
                    }

                    &[data-canvas-selector="ig"] {
                       background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 103%;
                        left: 50%;
                        margin-left: -25px;
                        width: 0;
                        height: 0;
                        border-top: solid 25px $charcoal;
                        border-left: solid 25px transparent;
                        border-right: solid 25px transparent;

                        @include breakpoint(mobile, max) {
                            margin-left: -22px;
                            border-top: solid 22px $charcoal;
                            border-left: solid 22px transparent;
                            border-right: solid 22px transparent;
                        }
    
                    }
                 }
             }
        }
    }
}

.design-frames {
    padding: 3em 0;

    @include breakpoint(tablet, max) {
        padding: 1em 0;
    }

    [data-canvas-placeholder="profile"] {
        width: 360px;
        height: auto;
    }

    .design-frames-inner {
        display: flex;

        @include breakpoint(tablet, max) {
            display: block;
        }
    }

    .profile-frame, .header-frame {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0 50%;

        .canvas-column {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            flex-direction: column;
        }

        .input-column {
            margin-top: 2em;
        }
    }

    .canvas-wrapper {
        position: relative;
        display: block;
        margin: 0 auto;
        overflow: hidden;

        &.has-image {
            .toolbar {
                display: block;
                width: 100%;
            }
        }

        &.profile-canvas {
            width: 220px;
            height: 220px;
            background: $light-gray;
            border-radius: 50%;

            img {
                height: 100%;
                max-width: none;
            }

            .toolbar {
                bottom: .5em;
            }
        }

        &.header-canvas {
            position: relative;
            width: 100%;

            @include breakpoint(tablet, max) {
                margin-top: 2em;
            }

            img {
                height: auto;
                max-width: 100%;
            }
        }

        .toolbar {
            display: none;
            position: absolute;
            bottom: 2em;
            left: 50%;
            transform: translateX(-50%);

            button {
                color: $white;
                background: $medium-blue;
                border: none;
                border-radius: 6px;
                font-size: .75em;
                padding: .5em;
                letter-spacing: 1px;
                font-family: $libre;
                cursor: pointer;
            }
        }
    }

    .header-frame {
        display: none;

        &.active {
            display: block;
        }
    }

    .canvas-thumbs {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: .5em 0;

        @include breakpoint(tablet, max) {
            flex-wrap: wrap;
            margin: 0 -.5em;

            p {
                flex: 1 0 100%;
            }
        }

        .canvas-thumb {
            max-width: 200px;
            margin: 0 .5em;
            opacity: 0.5;
            transition: all .3s ease;

            &.active {
                opacity: 1;
            }

            img {
                max-width: 100%;
                height: auto;
                vertical-align: middle;
            }


            @include breakpoint(tablet, max) {
                max-width: calc(50% - 1em);
            }
        }
    }
}