h2 {
    font-family: $merriweather;
    font-size: 4.25em;
    color: $black;

    @include breakpoint(tablet, max) {
        font-size: 3.5rem;
    }

    @include breakpoint(mobile, max) {
        font-size: 1.25rem;
    }
}

p,a,div {
    font-family: $oswald;
    font-weight: 300;
    color: $black;
}