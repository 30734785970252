img {
    height: auto;
    max-width: 100%;
}

a, button, input {
    outline: 0;
}

.no-results {
    margin: 2em auto;

    font-size: 2rem;

    @include breakpoint(tablet, max) {
        font-size: 1rem;
    }
}

p.large {
    font-size: 1.25rem;
}

.button {
    padding: 1em 2em;
    margin: 1em;
    font-size: 1.125rem;
    font-weight: 400;
    border-radius: 30px;
    text-decoration: none;
    display: inline-block;
    
    cursor: pointer;
    font-family: $oswald;
    transition: all .2s ease;
    border: 0;

    &.slim {
        padding: .5em 2em;
    }

    &.bl {
        background-color: $black;
        color: $white !important;
        &:hover {
            background-color: $dark-red;
        }
    }

    &.rd {
        background-color: $red;
        color: $white !important;
        &:hover {
            background-color: $dark-red;
        }
    }

    i {
        margin-left: .5rem;
        font-size: .75em;
    }
}

.btn-ctr {
    display: flex !important;
    justify-content: center;

    @include breakpoint(mobile, max) {
        .button {
            width: 90%;
            margin: 1.125rem 0;
            padding: 1.125rem 0;
            text-align: center;
        }
    }
}

select.rounded {
    background-image: url('/img/field.png');
    background-size: contain;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: right;
    cursor: pointer;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box; 
    box-sizing:content-box;

    border: 1px solid $extra-light-gray;

   
    border-radius: 25px;

    font-size: .75rem;
    padding: 1rem 2.5rem 1rem 1rem;
    font-family: $libre;
    color: $gray;
    font-weight: 600;

    &::-ms-expand {
        display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
    }
}

.hidden {
    display: none !important;
}