section.example-systems {
    display: none;
    margin: 0 auto;
    width: 85.7vw;

    &.active {
        display: block;
    }

    .menu-row {
        justify-content: space-between;

        .row-left {
            .button {
                margin: 0;
                padding: .5rem 2rem;
            }
        }

        .row-right {
            .sort-menu {
                width: 100%;
            }
            select {
                padding: .75rem 0 .75rem 1rem;
                width: 11vw;

                &:first-of-type {
                    margin-right: 2rem;
                }
            }
        }
    }
    .systems-grid-wrapper {
        width: 103%;
        margin: 0 -1.5%;
    }
    .systems-grid {
        margin: 0 0 5rem;
        &>a {
            flex-basis: 22.5%;
            outline: 1px solid $extra-light-gray;
            background-color: $ultra-light-gray;
            margin: 2.8vw 1.25% 0;
            text-decoration: none;
            transition: all .2s ease;

            &.item {
                display: none;

                &.active {
                    display: flex;
                }
            }


            .system {
                
    
                .img-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 6.5%;
                    height: 19.4vw;
                    border-bottom: 1px solid $extra-light-gray;
                    background-color: $white;
                    transition: border-bottom .2s ease;
    
                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
    
                    i {
                        font-size: 4rem;
                        color: $light-gray;
                    }
                }
    
                .system-content {
                    padding: 1rem;
                    .title {
                        font-size: 1.125rem;
                        margin-bottom: .75rem;
                        color: $dark-charcoal;
                        font-weight: 400;
                        transition: color .2s ease;
    
                    }
    
                    .desc {
                        font-family: $merriweather;
                        font-size: 0.6875rem;
                        color: $dark-charcoal;
                        line-height: 1.63em;
                        transition: color .2s ease;
                    }
                }
                
            }

            &:hover {
                outline: 1px solid $black;
                background-color: $charcoal;

                .system {
                    .img-wrapper {
                        border-bottom: 1px solid $black;
                    }
        
                    .system-content {
                        .title {
                            color: $white;
        
                        }
        
                        .desc {
                            color: $white;
                        }
                    }
                    
                }
            }
        }
        
    }
    @include breakpoint(tablet, max) {
        .menu-row {
            .row-right {
                flex-basis: 64%;
                justify-content: space-between;
                display: flex;
                
                select {
                    width: 45%;
                    margin: 0;

                    &:first-of-type {
                        margin: 0;
                    }
                }
            }
        }
        .systems-grid-wrapper {
            width: 103%;
            margin: 0 -1.5%;
        }
        .systems-grid {
            margin: 3rem 0 5rem;
            &>a {
                flex-basis: 48%;
                margin: 0 1% 10vw;

                .system {
                    .img-wrapper {
                        height: 40vw;
                    }
                    
                }
            }
            
        }
    }

    @include breakpoint(mobile, max) {
        .menu-row {
            .row-left {
                flex-basis: 100%;

                .button {
                    margin: 1rem 0 1.5rem 0;
                    width: 100%;
                    text-align: center;
                    padding: .5rem 0;
                }
            }
    
            .row-right {
                flex-basis: 100%;
                select {
                    padding: .75rem 0 .75rem 5%;
                    width: 95%;
                    &:first-of-type {
                        margin: 0 0 1.5rem;
                    }
                }
            }
        }
    
        .systems-grid {
            margin: 3rem 0 5rem;
            &>a {
                flex-basis: 100%;
                margin-right: 0;
                margin-bottom: 10vw;

                .system {
                    .img-wrapper {
                        height: 80vw;
                    }
                    
                }
            }
            
        }
    }
}