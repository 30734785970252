
@import './node_modules/@artisandm/speyside/src/app.scss';
@import './node_modules/cookieconsent/build/cookieconsent.min';

@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:400,600,900&display=swap');

@import 'vendor/vendor';
@import 'util/variables';
@import 'util/typography';
@import 'util/util';
@import 'block/videoBlock';
@import 'block/newsBlock';
@import 'block/twitterBlock';
@import 'block/buttonCallout';
@import 'block/photoCallout';
@import 'block/shareGrid';
@import 'block/rotaryBlock';
@import 'block/pictureGrid';
@import 'block/designBlock';
@import 'block/storyForm';
@import 'block/bodyBlock';
@import 'theme/theme';
@import 'layout/layout';
@import 'component/component';
@import 'component/form';
@import 'component/header';
@import 'component/menu-toggle';
@import 'component/footer';
@import 'component/sortMenu';
@import 'component/systemSelector';
@import 'component/discipleship-systems/guides';
@import 'component/discipleship-systems/exampleSystems';
@import 'component/discipleship-systems/courses';
@import 'component/discipleship-systems/systemSingle';
@import 'page/page';

// Cookie Consent Custom Styles
.cc-message, .cc-link {
	font-family:$libre;
    font-weight:400;
    font-size:.8125rem;
}
.cc-btn {
	font-family:$libre;
}
.cc-revoke.cc-bottom {
	font-family:$libre;
	font-size:.8125rem !important;
	right:50px !important;
	bottom:0 !important;
	left:auto !important;
}
.cc-type-info {
	display:none !important;
}
.cc-animate.cc-revoke.cc-bottom,
.cc-animate.cc-revoke.cc-bottom.cc-active {
	transform:none !important;
	transition:none !important;
	bottom:0 !important;
}