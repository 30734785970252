
.header {
    &.dark {
        background-color: $navy-blue;

        .menu-right-item {
            &.social-block {
                a {
                    i {
                        color: $navy-blue;
                    }
                }
            }
        }
    }

    &.bg {
        background-size: cover !important;
        background-position: center top !important;
    }

    &.black {
        background-color: $charcoal;
    }

    &.light {
        background-color: $light-blue;
    }

    &.text-dark {
        .header-message {
            color: $black;
        }
    }

    &.text-white {
        .header-message {
            color: $white;

            

            h1 {
                color: $white;
            }

            p {
                color: $white;
            }
        }
    }
    
    .mobile-menu-bar {
        display: none;
        .mobile-logo {
            margin: 1.75em 1em;
            
            a {
                img {
                    width: 210px;
                    &:hover {
                        filter: invert(.475) sepia(9) saturate(5) hue-rotate(2deg);
                    }
                }
            }
        }
        .mobile-menu {
            i {
                color: $white;
                font-size: 1.5em;
                margin: 1em;
            }
        }
    }
    
    .menu {
        padding: 2em 0;
        &-left {
            justify-content: flex-start;
            align-items: center;
            &-item {
                margin: 0 .75em;
                a {
                    font-family: $oswald;
                    color: $white;
                    text-decoration: none;
                    font-weight: 300;
                    font-size: 1.5em;
                    transition: color .2s ease;

                    &:hover, &.active {
                        color: $yellow;
                    }

                    @include breakpoint(1200px,max) {
                        font-size: 1.25em;
                    }
                }
            }
        }

        &-right {
            justify-content: flex-end;
            align-items: center;
            &-item {
                margin: 0 .75em;
                a {
                    font-family: $oswald;
                    color: $white;
                    text-decoration: none;
                    font-weight: 300;
                    font-size: 1.125em;
                    transition: color .2s ease;

                    display: flex;
                    align-items: center;
                    &:hover {
                        color: $yellow;
                    }

                    @include breakpoint(1200px,max) {
                        font-size: .85em;
                    }

                    i {
                        padding-left: .5rem;
                        font-size: .65em;
                    }
                }
                &.social-block {
                    a{
                        width: 25px;
                        height: 25px;
                        position: relative;
                        margin: 0 3px;
                        display: inline;

                        img {
                            vertical-align: baseline;
                            padding-top: .25em;
                            -webkit-transition : -webkit-filter .2s linear;
                            &:hover {
                                filter: invert(.475) sepia(9) saturate(5) hue-rotate(2deg);
                            }
                        }
                    }
                }
            }
        }
    }

    .logo {
        width: 40vw;
        margin: 0 auto 55px;

        img {
            margin-bottom: 55px;
            width: 100%;
            -webkit-transition: -webkit-filter .2s linear;
            &:hover {
                filter: invert(.475) sepia(9) saturate(5) hue-rotate(2deg);
            }
        }

        hr {
            width: 100px;
            margin: 0 auto;
            border: 2px solid $white;
        }

        
    }
    .header-message {
        text-align: center;
        margin: 2em 0 0;
        padding-bottom: 2em;

        &.w-board {
            h1 {
                font-size: 3rem;
            }

            p {
                font-size: 1.75rem;
            }
        }

        &.gap {
            h1 {
                margin: .83em 8rem;
            }
        }

        h1 {
            font-size: 4.25rem;
            margin: .83em 8rem 0;
            font-family: $merriweather;
        }

        p {
            font-size: 1.375em;
            width: 50%;
            margin: 3.3rem auto 0;
        }

        .button {
            margin-top: 3rem;
        }
    }

    section.img-board {
        position: relative;
        height: 725px;
        margin-top: 2em;
        .mood-modal {
            &:hover {
                .img,.plus,.people-icon {
                    box-shadow: 0 0 0 5px  $dark-blue;
                    border-radius: 100%;
                }
            }
            padding: 20px;
            position: absolute;
            cursor: pointer;

            .img {
                transition: width 1s, height 1s;
                transition-delay: 2s;
            }

            .blockquote {
                position: relative;
                display: none;
                color: $white;
                font-family: $merriweather;
                
                h3 {
                    font-size: 2.25rem;
                }

                @include breakpoint(tablet, max) {
                    h3 {
                        font-size: 1.75rem;
                    }
                }

                @include breakpoint(mobile, max) {
                    h3 {
                        font-size: 1.25rem;
                    }
                }

                &:before {
                    content: '“';
                    content: open-quote;
                    position: absolute;
                    top: -2rem;
                    left: 0;
                    color: $white;
                    font-family: $merriweather;
                    font-size: 3rem;
                }
            }

            .button {
                display: none;
            }

            .people-icon {
                display: none;
            }

            &.shrink {
                .img {
                    width: 0px !important;
                    height: 0px !important;
                }
            }

            .heading {
                margin: 1rem 0;
            }

            &.modal1 {
                left: 5%;
                top: -5%;

                border-radius: 100px 10px 10px 10px;

                .heading {
                    float: right;
                    width: 50%;
                }

                .message {
                    clear: both;
                    padding: 1em 0;
                }
                .img {
                    width: 240px;
                    height: 240px;
                    float: left;
                }
            }

            &.modal2 {
                left: 10%;
                bottom: 20%;

                border-radius: 10px 10px 10px 120px;

                .message {
                    margin: 0 0 2em;
                }

                .img {
                    width: 258px;
                    height: 258px;
                    float: left;
                }
            }

            &.modal3 {
                left: 38%;
                top: 20%;

                border-radius: 100px 10px 10px 10px;

                .heading {
                    float: right;
                    width: 50%;
                }

                .message {
                    clear: both;
                    padding: 1em 0;
                }

                .img {
                    width: 227px;
                    height: 227px;
                    float: left;
                }
            }
            
            &.modal4 {
                left: 35%;
                bottom: 5%;

                border-radius: 10px 10px 10px 90px;

                .message {
                    margin: 0 0 2em;
                }

                .control-block {

                    .control-row {
                        padding-bottom: .5em;
                    }
                }

                .img {
                    width: 191px;
                    height: 191px;
                    float: left;
                }
            }

            &.modal5 {
                right: 30%;
                bottom: 35%;

                border-radius: 10px 10px 80px 10px;

                .control-block {

                    .control-row {
                        padding-bottom: .5em;
                    }
                }

                .message {
                    margin: 0 0 2em;
                }

                .img {
                    width: 176px;
                    height: 176px;
                    float:right;

                    margin-top:  12px;
                }
            }

            &.modal6 {
                right: 5%;
                top: 0%;

                border-radius: 10px 110px 10px 10px;

                .heading {
                    float: left;
                    width: 55%;
                }

                .message {
                    clear: both;
                    padding: 1em 0;
                }

                .img {
                    width: 204px;
                    height: 204px;

                    float: right;
                }
            }

            &.modal7 {
                right: 8%;
                bottom: 10%;

                border-radius: 10px 10px 120px 10px;

                .message {
                    margin: 0 0 2em;
                }

                .img {
                    width: 258px;
                    height: 258px;
                    float: right;
                }
            }

            &.modal8 {
                left: 25%;
                top: 15%;

                border-radius: 70px 10px 10px 10px;

                .heading {
                    float: right;
                    width: 65%;
                    font-size: 2.125rem;
                }

                .message {
                    clear: both;
                    padding: 1em 0;
                }

                .plus,.people-icon {
                    width: 142px;
                    height: 142px;
                    float: left;
                }
            }

            &.modal9 {
                right: 20%;
                top: 30%;

                border-radius: 10px 60px 10px 10px;

                .heading {
                    float: left;
                    width: 70%;
                }

                .message {
                    clear: both;
                    padding: 1em 0;
                }

                .plus,.people-icon {
                    width: 103px;
                    height: 103px;
                    float: right;
                }
            }

            &.modal10 {
                right: 38%;
                bottom: 10%;

                .plus,.people-icon {
                    height: 93px;
                    width: 93px;
                    float: right;
                    margin-top: 90px;
                }

                border-radius: 10px 10px 60px 10px;

                .message {
                    margin: 0 0 2em;
                }

                .control-block {

                    .control-row {
                        padding-bottom: .5em;
                    }
                }
            }

            &.active {
                background: $transBlack;
                &:hover {
                    img {
                        box-shadow: none;
                    }
                    
                }
                width: 500px;
                cursor: initial;
                z-index: 10;

                .blockquote {
                    display: block;
                }

                .button {
                    display: block;
                }

                .plus {
                    display: none;
                }

                .people-icon {
                    display: block;
                    border: 4px solid $white;
                    border-radius: 90%;
                    padding: 4px;
                }
                
                .video-block {
                    margin-bottom: 1em;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .play {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: $yellow;
                        border-radius: 50%;
                        width: 70px;
                        height: 70px;
                        transition: background-color .2s ease;

                        &:hover {
                            background-color: $red;
                        }

                        i {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 2rem;
                            color: $white;
                        }
                    }
                }
                h3,p,div,.control-block,.control-row, {
                    display: block;
                }
                .button-row {
                    display: flex;
                }

                .button {
                    display: inline-block;
                    margin: 0 auto;
                }
            }

            .control-row {
                text-align: center;
                padding: 1.5em 0;
                font-size: 1.5em;

                a {
                    cursor: pointer;
                }

                .prev,.next {
                    font-weight: 700;
                    margin: 0 1.5em;
                }
                .close {
                    color: $gray;
                }
            }
            
            h3 {
                font-family: $merriweather;
                color: $white;
                font-size: 2.25rem;
            }
            p {
                color: $white;
                font-size: 1.25rem;
            }
            a {
                color: $white;
            }
    
            h3,p,div {
                display: none;
            }

            @media screen and (max-width: 1200px) {
                &.modal3 {
                    display: none;
                }

                &.modal10 {
                    display: none !important;
                }
            }

            @include breakpoint(tablet, max) {
                &.modal3 {
                    display: none;
                }

                &.active {
                    border-radius: 10px;
                    background: $transBlackDark;
                    left: 0;
                    display: flex;
                    flex-wrap: wrap;
                    position: fixed;
                    flex-direction: column;
                    top: 65px !important;
                    left: 5% !important;
                    width: 80%;
                    height: 80%;
                    transition: none;
                    padding: 5%;
                    align-items: center;
                    z-index: 100;

                    .img {
                        height: 30vw !important;
                        width: 30vw !important;
                        margin-top: 12px;
                        float: none;
                        order: -1;
                    }

                    .video-block {
                        margin-top: 3em;
                    }
                    .heading {
                        float: none;
                        width: 100%;
                        margin: .75em 0;
                    }
                    .control-block {
                        width: 100%;
                        flex-grow: 2;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
            }

            @media screen and (max-width: 1400px) {
                &.modal1 {
                    left: 1%;
                }
                &.modal2 {
                    left: 10%;
                }
                &.modal3 {
                    left: 37%;
                }
                &.modal4 {
                    left: 35%;
                }
                &.modal5 {
                    right: 22%;
                }
                &.modal6 {
                    right: 1%;
                }
                &.modal7 {
                    right: 2%;
                }
                &.modal8 {
                    left: 24%;
                }
                &.modal9 {
                    right: 10%;
                }
                &.modal10 {
                    right: 30%;
                }
            }  
            
            @media screen and (max-width: 1200px) {
                &.modal1 {
                    left: 1%;
                }
                &.modal2 {
                    left: 10%;
                }
                &.modal3 {
                    left: 37%;
                }
                &.modal4 {
                    left: 40%;
                }
                &.modal5 {
                    right: 28%;
                }
                &.modal6 {
                    right: 1%;
                }
                &.modal7 {
                    right: 2%;
                }
                &.modal8 {
                    left: 32%;
                }
                &.modal9 {
                    right: 15%;
                }
            }  

            @media screen and (max-width: 900px) {
                &.modal1 {
                    top: 2.5%;
                    left: 2.5%;
                }

                &.modal2 {
                    bottom: 2.5%;
                    left: 2.5%;
                }

                &.modal4 {
                    display: none;
                }

                &.modal6 {
                    right: 2.5%;
                    top: 10%;
                }

                &.modal7 {
                    bottom: 1%;
                    right: 7%;
                }

                &.modal8 {
                    top: 35%;
                    left: 25%;
                }

                &.modal9 {
                    display: none;
                }
            }

            @include breakpoint(mobile, max) {
                img {
                    transition: none !important;
                }
                h3 {
                    font-size: 1.75em;
                }
                p {
                    font-size: 1em;
                }

                &.active {
                    flex-wrap: nowrap;
                    justify-content: space-between !important;
                    img, h3, p, .control-block {
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                    .control-block {
                        flex-grow: 0;
                    }
                }

                .button {
                    width: 95%;
                    text-align: center;
                }
                
                &.modal1 {
                    top: 15%;
                    left: 2.5%;

                    .img {
                        width: 23vw;
                        height: 23vw;
                    }
                }

                &.modal2 {
                    display: none;
                }

                &.modal3 {
                    display: flex;
                    bottom: 8%;
                    left: 5%;
                    top: auto;
                    .img {
                        width: 26vw;
                        height: 26vw;
                    }
                }

                &.modal5 {
                    bottom: 20%;
                    right: 15%;
                    .img { 
                        width: 18vw;
                        height: 18vw;
                    }
                }

                &.modal6 {
                    top: 20%;
                    right: 7.5%;

                    .img {
                        width: 30vw;
                        height: 30vw;
                    }
                }

                &.modal7 {
                    display: none;
                }

                &.modal8 {
                    top: 40%;
                    left: 20%;
                    .plus,.people-icon {
                        width: 17vw;
                        height: 17vw;
                    }
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .header-message {

            &.w-board {
                h1 {
                    font-size: 2.75rem;
                    margin: .83em .5em 0;
                }
            }

            &.gap {
                h1 {
                    margin: .83em 2rem;
                }
            }

            h1 {
                font-size: 3.5rem;
                margin: .83em 1em 0;
            }
            p {
                font-size: 1.125rem;
                width: 70%;
                margin: 1em auto 0;
            }
        }
        
        .mobile-menu-bar {
            display: flex;
            justify-content: space-between;
        }
        .menu {
            padding: 0;
            &-right, &-left {
                display: none;
            }

            &.open {
                flex-direction: column;
                background-color: $navy-blue;
                justify-content: flex-start;
                position: fixed;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 11;
                
                .menu-right, .menu-left {
                    display: block;
                    flex-basis: auto !important;

                    .menu-left-item {
                        padding: .5em 1em;
                        margin: 0;

                        &:nth-of-type(odd) {
                            background-color: $dark-blue;
                        }
                    }
                    .menu-right-item {
                        padding: .5em 0;
                        margin: 0 1rem;
                        &.social-block {
                            a{
                                margin: 0 10px;
                                i{
                                    color: $navy-blue;
                                }
                            }
                        }
                    }
                }
            }
        }
        .logo {
            display: none;
        }
    }

    @include breakpoint(mobile, max) {
        
        
        .header-message {

            &.w-board {
                h1 {
                    font-size: 1.25rem;
                    margin: .83em .5em 0;
                }
            }

            &.gap {
                margin-top: 0;
                
                h1 {
                    margin: 0;
                }
            }

            h1 {
                font-size: 1.25rem;
                margin: .83em 2em 0;
            }
            p {
                font-size: 1rem;
                width: 90%;
                margin: 1em auto 0;
            }
        }

        section.img-board {
            height: 125vw;

            .btn-ctr {
                .button.bl {
                    width: 90%;
                    text-align: center;
                }
            }
        }
    }
    
}