@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,400i,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Libre+Franklin:400,600,900&display=swap");
html, body {
  margin: 0;
  padding: 0; }

.visualize:not(.grid), .grid.visualize :not(.grid)[class*='col'] {
  background-color: lightblue; }

.grid.gutter-1.collapse {
  width: auto;
  margin: -0; }

.grid.gutter-1 [class*='col'] {
  margin: 0; }

.grid.gutter-1-h.collapse {
  width: auto;
  margin-left: -0;
  margin-right: -0; }

.grid.gutter-1-h [class*='col'] {
  margin-left: 0;
  margin-right: 0; }

.grid.gutter-1-v.collapse {
  width: auto;
  margin-top: -0;
  margin-bottom: -0; }

.grid.gutter-1-v [class*='col'] {
  margin-top: 0;
  margin-bottom: 0; }

@media (max-width: 1200px) {
  .grid.gutter\@desktop-1.collapse {
    width: auto;
    margin: -0; }
  .grid.gutter\@desktop-1 [class*='col'] {
    margin: 0; }
  .grid.gutter\@desktop-1-h.collapse {
    width: auto;
    margin-left: -0;
    margin-right: -0; }
  .grid.gutter\@desktop-1-h [class*='col'] {
    margin-left: 0;
    margin-right: 0; }
  .grid.gutter\@desktop-1-v.collapse {
    width: auto;
    margin-top: -0;
    margin-bottom: -0; }
  .grid.gutter\@desktop-1-v [class*='col'] {
    margin-top: 0;
    margin-bottom: 0; } }

@media (max-width: 992px) {
  .grid.gutter\@laptop-1.collapse {
    width: auto;
    margin: -0; }
  .grid.gutter\@laptop-1 [class*='col'] {
    margin: 0; }
  .grid.gutter\@laptop-1-h.collapse {
    width: auto;
    margin-left: -0;
    margin-right: -0; }
  .grid.gutter\@laptop-1-h [class*='col'] {
    margin-left: 0;
    margin-right: 0; }
  .grid.gutter\@laptop-1-v.collapse {
    width: auto;
    margin-top: -0;
    margin-bottom: -0; }
  .grid.gutter\@laptop-1-v [class*='col'] {
    margin-top: 0;
    margin-bottom: 0; } }

@media (max-width: 768px) {
  .grid.gutter\@tablet-1.collapse {
    width: auto;
    margin: -0; }
  .grid.gutter\@tablet-1 [class*='col'] {
    margin: 0; }
  .grid.gutter\@tablet-1-h.collapse {
    width: auto;
    margin-left: -0;
    margin-right: -0; }
  .grid.gutter\@tablet-1-h [class*='col'] {
    margin-left: 0;
    margin-right: 0; }
  .grid.gutter\@tablet-1-v.collapse {
    width: auto;
    margin-top: -0;
    margin-bottom: -0; }
  .grid.gutter\@tablet-1-v [class*='col'] {
    margin-top: 0;
    margin-bottom: 0; } }

@media (max-width: 500px) {
  .grid.gutter\@mobile-1.collapse {
    width: auto;
    margin: -0; }
  .grid.gutter\@mobile-1 [class*='col'] {
    margin: 0; }
  .grid.gutter\@mobile-1-h.collapse {
    width: auto;
    margin-left: -0;
    margin-right: -0; }
  .grid.gutter\@mobile-1-h [class*='col'] {
    margin-left: 0;
    margin-right: 0; }
  .grid.gutter\@mobile-1-v.collapse {
    width: auto;
    margin-top: -0;
    margin-bottom: -0; }
  .grid.gutter\@mobile-1-v [class*='col'] {
    margin-top: 0;
    margin-bottom: 0; } }

.grid.flex {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .grid.flex.nowrap {
    flex-wrap: nowrap; }
    @media (max-width: 1200px) {
      .grid.flex.nowrap\@desktop {
        flex-wrap: nowrap; } }
    @media (max-width: 992px) {
      .grid.flex.nowrap\@laptop {
        flex-wrap: nowrap; } }
    @media (max-width: 768px) {
      .grid.flex.nowrap\@tablet {
        flex-wrap: nowrap; } }
    @media (max-width: 500px) {
      .grid.flex.nowrap\@mobile {
        flex-wrap: nowrap; } }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-1 {
    flex-basis: 8.33333%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-2 {
    flex-basis: 16.66667%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-3 {
    flex-basis: 25%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-4 {
    flex-basis: 33.33333%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-5 {
    flex-basis: 41.66667%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-6 {
    flex-basis: 50%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-7 {
    flex-basis: 58.33333%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-8 {
    flex-basis: 66.66667%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-9 {
    flex-basis: 75%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-10 {
    flex-basis: 83.33333%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-11 {
    flex-basis: 91.66667%; }
  .grid.flex [class='col'] {
    flex: 1 0 auto; }
  .grid.flex .col-12 {
    flex-basis: 100%; }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-1-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-1-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-1-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-1-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-1-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-1 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-2 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-3 {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-4 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-5 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-6 {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-7 {
      flex-basis: 58.33333%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-8 {
      flex-basis: 66.66667%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-9 {
      flex-basis: 75%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-10 {
      flex-basis: 83.33333%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-11 {
      flex-basis: 91.66667%; }
    .grid.flex .col\@desktop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1 .col-12 {
      flex-basis: 100%; }
    .grid.flex .col\@desktop-1.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-1.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-1.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-1.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-1.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-1.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-2-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-2-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-2-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-2-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-2-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-1 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-2 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-3 {
      flex-basis: 12.5%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-4 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-5 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-6 {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-7 {
      flex-basis: 29.16667%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-8 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-9 {
      flex-basis: 37.5%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-10 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-11 {
      flex-basis: 45.83333%; }
    .grid.flex .col\@desktop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2 .col-12 {
      flex-basis: 50%; }
    .grid.flex .col\@desktop-2.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-2.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-2.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-2.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-2.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-2.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-3-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-3-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-3-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-3-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-3-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-1 {
      flex-basis: 2.77778%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-2 {
      flex-basis: 5.55556%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-3 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-4 {
      flex-basis: 11.11111%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-5 {
      flex-basis: 13.88889%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-6 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-7 {
      flex-basis: 19.44444%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-8 {
      flex-basis: 22.22222%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-9 {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-10 {
      flex-basis: 27.77778%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-11 {
      flex-basis: 30.55556%; }
    .grid.flex .col\@desktop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3 .col-12 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@desktop-3.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-3.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-3.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-3.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-3.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-3.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-4-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-4-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-4-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-4-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-4-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-1 {
      flex-basis: 2.08333%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-2 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-3 {
      flex-basis: 6.25%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-4 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-5 {
      flex-basis: 10.41667%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-6 {
      flex-basis: 12.5%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-7 {
      flex-basis: 14.58333%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-8 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-9 {
      flex-basis: 18.75%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-10 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-11 {
      flex-basis: 22.91667%; }
    .grid.flex .col\@desktop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4 .col-12 {
      flex-basis: 25%; }
    .grid.flex .col\@desktop-4.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-4.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-4.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-4.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-4.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-4.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-5-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-5-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-5-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-5-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-5-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-1 {
      flex-basis: 1.66667%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-2 {
      flex-basis: 3.33333%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-3 {
      flex-basis: 5%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-4 {
      flex-basis: 6.66667%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-5 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-6 {
      flex-basis: 10%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-7 {
      flex-basis: 11.66667%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-8 {
      flex-basis: 13.33333%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-9 {
      flex-basis: 15%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-10 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-11 {
      flex-basis: 18.33333%; }
    .grid.flex .col\@desktop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5 .col-12 {
      flex-basis: 20%; }
    .grid.flex .col\@desktop-5.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@desktop-5.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@desktop-5.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@desktop-5.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@desktop-5.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@desktop-5.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-1-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-1-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-1-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-1-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-1-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-1 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-2 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-3 {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-4 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-5 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-6 {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-7 {
      flex-basis: 58.33333%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-8 {
      flex-basis: 66.66667%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-9 {
      flex-basis: 75%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-10 {
      flex-basis: 83.33333%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-11 {
      flex-basis: 91.66667%; }
    .grid.flex .col\@laptop-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1 .col-12 {
      flex-basis: 100%; }
    .grid.flex .col\@laptop-1.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-1.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-1.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-1.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-1.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-1.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-2-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-2-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-2-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-2-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-2-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-1 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-2 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-3 {
      flex-basis: 12.5%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-4 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-5 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-6 {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-7 {
      flex-basis: 29.16667%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-8 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-9 {
      flex-basis: 37.5%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-10 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-11 {
      flex-basis: 45.83333%; }
    .grid.flex .col\@laptop-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2 .col-12 {
      flex-basis: 50%; }
    .grid.flex .col\@laptop-2.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-2.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-2.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-2.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-2.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-2.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-3-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-3-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-3-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-3-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-3-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-1 {
      flex-basis: 2.77778%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-2 {
      flex-basis: 5.55556%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-3 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-4 {
      flex-basis: 11.11111%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-5 {
      flex-basis: 13.88889%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-6 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-7 {
      flex-basis: 19.44444%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-8 {
      flex-basis: 22.22222%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-9 {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-10 {
      flex-basis: 27.77778%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-11 {
      flex-basis: 30.55556%; }
    .grid.flex .col\@laptop-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3 .col-12 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@laptop-3.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-3.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-3.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-3.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-3.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-3.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-4-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-4-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-4-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-4-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-4-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-1 {
      flex-basis: 2.08333%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-2 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-3 {
      flex-basis: 6.25%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-4 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-5 {
      flex-basis: 10.41667%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-6 {
      flex-basis: 12.5%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-7 {
      flex-basis: 14.58333%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-8 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-9 {
      flex-basis: 18.75%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-10 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-11 {
      flex-basis: 22.91667%; }
    .grid.flex .col\@laptop-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4 .col-12 {
      flex-basis: 25%; }
    .grid.flex .col\@laptop-4.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-4.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-4.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-4.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-4.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-4.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-5-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-5-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-5-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-5-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-5-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-1 {
      flex-basis: 1.66667%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-2 {
      flex-basis: 3.33333%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-3 {
      flex-basis: 5%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-4 {
      flex-basis: 6.66667%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-5 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-6 {
      flex-basis: 10%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-7 {
      flex-basis: 11.66667%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-8 {
      flex-basis: 13.33333%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-9 {
      flex-basis: 15%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-10 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-11 {
      flex-basis: 18.33333%; }
    .grid.flex .col\@laptop-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5 .col-12 {
      flex-basis: 20%; }
    .grid.flex .col\@laptop-5.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@laptop-5.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@laptop-5.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@laptop-5.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@laptop-5.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@laptop-5.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-1-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-1-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-1-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-1-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-1-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-1 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-2 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-3 {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-4 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-5 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-6 {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-7 {
      flex-basis: 58.33333%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-8 {
      flex-basis: 66.66667%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-9 {
      flex-basis: 75%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-10 {
      flex-basis: 83.33333%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-11 {
      flex-basis: 91.66667%; }
    .grid.flex .col\@tablet-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1 .col-12 {
      flex-basis: 100%; }
    .grid.flex .col\@tablet-1.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-1.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-1.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-1.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-1.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-1.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-2-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-2-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-2-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-2-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-2-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-1 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-2 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-3 {
      flex-basis: 12.5%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-4 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-5 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-6 {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-7 {
      flex-basis: 29.16667%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-8 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-9 {
      flex-basis: 37.5%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-10 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-11 {
      flex-basis: 45.83333%; }
    .grid.flex .col\@tablet-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2 .col-12 {
      flex-basis: 50%; }
    .grid.flex .col\@tablet-2.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-2.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-2.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-2.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-2.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-2.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-3-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-3-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-3-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-3-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-3-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-1 {
      flex-basis: 2.77778%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-2 {
      flex-basis: 5.55556%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-3 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-4 {
      flex-basis: 11.11111%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-5 {
      flex-basis: 13.88889%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-6 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-7 {
      flex-basis: 19.44444%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-8 {
      flex-basis: 22.22222%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-9 {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-10 {
      flex-basis: 27.77778%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-11 {
      flex-basis: 30.55556%; }
    .grid.flex .col\@tablet-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3 .col-12 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@tablet-3.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-3.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-3.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-3.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-3.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-3.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-4-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-4-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-4-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-4-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-4-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-1 {
      flex-basis: 2.08333%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-2 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-3 {
      flex-basis: 6.25%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-4 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-5 {
      flex-basis: 10.41667%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-6 {
      flex-basis: 12.5%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-7 {
      flex-basis: 14.58333%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-8 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-9 {
      flex-basis: 18.75%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-10 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-11 {
      flex-basis: 22.91667%; }
    .grid.flex .col\@tablet-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4 .col-12 {
      flex-basis: 25%; }
    .grid.flex .col\@tablet-4.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-4.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-4.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-4.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-4.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-4.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-5-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-5-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-5-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-5-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-5-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-1 {
      flex-basis: 1.66667%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-2 {
      flex-basis: 3.33333%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-3 {
      flex-basis: 5%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-4 {
      flex-basis: 6.66667%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-5 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-6 {
      flex-basis: 10%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-7 {
      flex-basis: 11.66667%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-8 {
      flex-basis: 13.33333%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-9 {
      flex-basis: 15%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-10 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-11 {
      flex-basis: 18.33333%; }
    .grid.flex .col\@tablet-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5 .col-12 {
      flex-basis: 20%; }
    .grid.flex .col\@tablet-5.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@tablet-5.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@tablet-5.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@tablet-5.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@tablet-5.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@tablet-5.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force [class*='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-1-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-1-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-1-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-1-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-1-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-1 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-2 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-3 {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-4 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-5 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-6 {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-7 {
      flex-basis: 58.33333%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-8 {
      flex-basis: 66.66667%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-9 {
      flex-basis: 75%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-10 {
      flex-basis: 83.33333%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-11 {
      flex-basis: 91.66667%; }
    .grid.flex .col\@mobile-1 [class='col'] {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1 .col-12 {
      flex-basis: 100%; }
    .grid.flex .col\@mobile-1.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-1.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-1.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-1.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-1.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-1.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force [class*='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-2-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-2-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-2-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-2-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-2-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-1 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-2 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-3 {
      flex-basis: 12.5%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-4 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-5 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-6 {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-7 {
      flex-basis: 29.16667%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-8 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-9 {
      flex-basis: 37.5%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-10 {
      flex-basis: 41.66667%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-11 {
      flex-basis: 45.83333%; }
    .grid.flex .col\@mobile-2 [class='col'] {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2 .col-12 {
      flex-basis: 50%; }
    .grid.flex .col\@mobile-2.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-2.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-2.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-2.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-2.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-2.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force [class*='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-3-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-3-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-3-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-3-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-3-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-1 {
      flex-basis: 2.77778%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-2 {
      flex-basis: 5.55556%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-3 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-4 {
      flex-basis: 11.11111%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-5 {
      flex-basis: 13.88889%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-6 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-7 {
      flex-basis: 19.44444%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-8 {
      flex-basis: 22.22222%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-9 {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-10 {
      flex-basis: 27.77778%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-11 {
      flex-basis: 30.55556%; }
    .grid.flex .col\@mobile-3 [class='col'] {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3 .col-12 {
      flex-basis: 33.33333%; }
    .grid.flex .col\@mobile-3.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-3.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-3.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-3.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-3.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-3.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force [class*='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-4-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-4-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-4-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-4-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-4-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-1 {
      flex-basis: 2.08333%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-2 {
      flex-basis: 4.16667%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-3 {
      flex-basis: 6.25%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-4 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-5 {
      flex-basis: 10.41667%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-6 {
      flex-basis: 12.5%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-7 {
      flex-basis: 14.58333%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-8 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-9 {
      flex-basis: 18.75%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-10 {
      flex-basis: 20.83333%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-11 {
      flex-basis: 22.91667%; }
    .grid.flex .col\@mobile-4 [class='col'] {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4 .col-12 {
      flex-basis: 25%; }
    .grid.flex .col\@mobile-4.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-4.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-4.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-4.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-4.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-4.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force [class*='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5-force.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-5-force.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-5-force.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-5-force.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-5-force.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-5-force.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-1 {
      flex-basis: 1.66667%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-2 {
      flex-basis: 3.33333%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-3 {
      flex-basis: 5%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-4 {
      flex-basis: 6.66667%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-5 {
      flex-basis: 8.33333%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-6 {
      flex-basis: 10%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-7 {
      flex-basis: 11.66667%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-8 {
      flex-basis: 13.33333%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-9 {
      flex-basis: 15%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-10 {
      flex-basis: 16.66667%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-11 {
      flex-basis: 18.33333%; }
    .grid.flex .col\@mobile-5 [class='col'] {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5 .col-12 {
      flex-basis: 20%; }
    .grid.flex .col\@mobile-5.gutter-1.collapse {
      width: auto;
      margin: -0; }
    .grid.flex .col\@mobile-5.gutter-1 [class*='col'] {
      margin: 0; }
    .grid.flex .col\@mobile-5.gutter-1-h.collapse {
      width: auto;
      margin-left: -0;
      margin-right: -0; }
    .grid.flex .col\@mobile-5.gutter-1-h [class*='col'] {
      margin-left: 0;
      margin-right: 0; }
    .grid.flex .col\@mobile-5.gutter-1-v.collapse {
      width: auto;
      margin-top: -0;
      margin-bottom: -0; }
    .grid.flex .col\@mobile-5.gutter-1-v [class*='col'] {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-1 {
      flex-basis: 8.33333%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-2 {
      flex-basis: 16.66667%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-3 {
      flex-basis: 25%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-4 {
      flex-basis: 33.33333%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-5 {
      flex-basis: 41.66667%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-6 {
      flex-basis: 50%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-7 {
      flex-basis: 58.33333%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-8 {
      flex-basis: 66.66667%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-9 {
      flex-basis: 75%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-10 {
      flex-basis: 83.33333%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-11 {
      flex-basis: 91.66667%; } }
  @media (max-width: 1200px) {
    .grid.flex .col\@desktop-12 {
      flex-basis: 100%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-1 {
      flex-basis: 8.33333%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-2 {
      flex-basis: 16.66667%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-3 {
      flex-basis: 25%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-4 {
      flex-basis: 33.33333%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-5 {
      flex-basis: 41.66667%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-6 {
      flex-basis: 50%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-7 {
      flex-basis: 58.33333%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-8 {
      flex-basis: 66.66667%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-9 {
      flex-basis: 75%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-10 {
      flex-basis: 83.33333%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-11 {
      flex-basis: 91.66667%; } }
  @media (max-width: 992px) {
    .grid.flex .col\@laptop-12 {
      flex-basis: 100%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-1 {
      flex-basis: 8.33333%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-2 {
      flex-basis: 16.66667%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-3 {
      flex-basis: 25%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-4 {
      flex-basis: 33.33333%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-5 {
      flex-basis: 41.66667%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-6 {
      flex-basis: 50%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-7 {
      flex-basis: 58.33333%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-8 {
      flex-basis: 66.66667%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-9 {
      flex-basis: 75%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-10 {
      flex-basis: 83.33333%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-11 {
      flex-basis: 91.66667%; } }
  @media (max-width: 768px) {
    .grid.flex .col\@tablet-12 {
      flex-basis: 100%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-1 {
      flex-basis: 8.33333%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-2 {
      flex-basis: 16.66667%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-3 {
      flex-basis: 25%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-4 {
      flex-basis: 33.33333%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-5 {
      flex-basis: 41.66667%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-6 {
      flex-basis: 50%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-7 {
      flex-basis: 58.33333%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-8 {
      flex-basis: 66.66667%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-9 {
      flex-basis: 75%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-10 {
      flex-basis: 83.33333%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-11 {
      flex-basis: 91.66667%; } }
  @media (max-width: 500px) {
    .grid.flex .col\@mobile-12 {
      flex-basis: 100%; } }

.cc-window {
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease; }

.cc-window.cc-invisible {
  opacity: 0; }

.cc-animate.cc-revoke {
  -webkit-transition: transform 1s ease;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease,-webkit-transform 1s ease; }

.cc-animate.cc-revoke.cc-top {
  -webkit-transform: translateY(-2em);
  transform: translateY(-2em); }

.cc-animate.cc-revoke.cc-bottom {
  -webkit-transform: translateY(2em);
  transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-top {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.cc-animate.cc-revoke.cc-active.cc-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.cc-revoke:hover {
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.cc-grower {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 1s;
  transition: max-height 1s; }

.cc-revoke, .cc-window {
  position: fixed;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Helvetica,Calibri,Arial,sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999; }

.cc-window.cc-static {
  position: static; }

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }

.cc-revoke {
  padding: .5em; }

.cc-revoke:hover {
  text-decoration: underline; }

.cc-header {
  font-size: 18px;
  font-weight: 700; }

.cc-btn, .cc-close, .cc-link, .cc-revoke {
  cursor: pointer; }

.cc-link {
  opacity: .8;
  display: inline-block;
  padding: .2em;
  text-decoration: underline; }

.cc-link:hover {
  opacity: 1; }

.cc-link:active, .cc-link:visited {
  color: initial; }

.cc-btn {
  display: block;
  padding: .4em .8em;
  font-size: .9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap; }

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent; }

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
  background-color: transparent;
  text-decoration: underline; }

.cc-close {
  display: block;
  position: absolute;
  top: .5em;
  right: .5em;
  font-size: 1.6em;
  opacity: .9;
  line-height: .75; }

.cc-close:focus, .cc-close:hover {
  opacity: 1; }

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em; }

.cc-revoke.cc-left {
  left: 3em;
  right: unset; }

.cc-revoke.cc-right {
  right: 3em;
  left: unset; }

.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 1em; }

.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; }

.cc-window.cc-banner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-banner .cc-message {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
  margin-right: 1em; }

.cc-compliance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: justify;
  align-content: space-between; }

.cc-floating .cc-compliance > .cc-btn {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.cc-btn + .cc-btn {
  margin-left: .5em; }

@media print {
  .cc-revoke, .cc-window {
    display: none; } }

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0; }
  .cc-window.cc-bottom {
    bottom: 0; }
  .cc-window.cc-banner, .cc-window.cc-floating, .cc-window.cc-left, .cc-window.cc-right {
    left: 0;
    right: 0; }
  .cc-window.cc-banner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .cc-window.cc-banner .cc-compliance {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .cc-window.cc-floating {
    max-width: none; }
  .cc-window .cc-message {
    margin-bottom: 1em; }
  .cc-window.cc-banner {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset; }
  .cc-window.cc-banner .cc-message {
    margin-right: 0; } }

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block; }

.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em;
  margin-bottom: 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: .8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

h2 {
  font-family: "Merriweather", serif;
  font-size: 4.25em;
  color: #2b2b2a; }
  @media (max-width: 1023px) {
    h2 {
      font-size: 3.5rem; } }
  @media (max-width: 767px) {
    h2 {
      font-size: 1.25rem; } }

p, a, div {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  color: #2b2b2a; }

img {
  height: auto;
  max-width: 100%; }

a, button, input {
  outline: 0; }

.no-results {
  margin: 2em auto;
  font-size: 2rem; }
  @media (max-width: 1023px) {
    .no-results {
      font-size: 1rem; } }

p.large {
  font-size: 1.25rem; }

.button {
  padding: 1em 2em;
  margin: 1em;
  font-size: 1.125rem;
  font-weight: 400;
  border-radius: 30px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  transition: all .2s ease;
  border: 0; }
  .button.slim {
    padding: .5em 2em; }
  .button.bl {
    background-color: #2b2b2a;
    color: #ffffff !important; }
    .button.bl:hover {
      background-color: #ba211a; }
  .button.rd {
    background-color: #db423c;
    color: #ffffff !important; }
    .button.rd:hover {
      background-color: #ba211a; }
  .button i {
    margin-left: .5rem;
    font-size: .75em; }

.btn-ctr {
  display: flex !important;
  justify-content: center; }
  @media (max-width: 767px) {
    .btn-ctr .button {
      width: 90%;
      margin: 1.125rem 0;
      padding: 1.125rem 0;
      text-align: center; } }

select.rounded {
  background-image: url("/img/field.png");
  background-size: contain;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid #d8d8d8;
  border-radius: 25px;
  font-size: .75rem;
  padding: 1rem 2.5rem 1rem 1rem;
  font-family: "Libre Franklin", sans-serif;
  color: #575757;
  font-weight: 600; }
  select.rounded::-ms-expand {
    display: none;
    /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */ }

.hidden {
  display: none !important; }

.fancybox-slide--video .fancybox-content {
  max-width: 100%;
  max-height: 100%; }
  @media (min-width: 1024px) {
    .fancybox-slide--video .fancybox-content {
      max-width: 50%;
      max-height: 50%; } }

section.video-block {
  background-color: #1f3254; }
  section.video-block.right .video-wrap {
    padding: 4em 0 2em 0;
    margin-left: 6em; }
    section.video-block.right .video-wrap .video-thumb .play {
      right: 15%; }
  section.video-block.left .video-wrap {
    padding: 4em 0 2em 0;
    margin-right: 6em; }
    section.video-block.left .video-wrap .video-header {
      display: flex;
      justify-content: flex-end;
      width: 100%; }
    section.video-block.left .video-wrap .video-thumb .play {
      left: 15%; }
    section.video-block.left .video-wrap .video-details div {
      margin-left: 2em; }
  section.video-block .video-wrap {
    position: relative;
    overflow-x: hidden; }
    section.video-block .video-wrap .video-header {
      position: absolute;
      width: 100%;
      z-index: 10; }
      section.video-block .video-wrap .video-header h2 {
        color: #ffffff;
        font-size: 4.25em; }
    section.video-block .video-wrap .video {
      align-items: center;
      margin-top: 9em; }
      section.video-block .video-wrap .video.no-header {
        margin-top: 2.5em; }
      section.video-block .video-wrap .video-details {
        height: 100%;
        margin-top: 5em; }
        section.video-block .video-wrap .video-details .video-message {
          width: 66%;
          margin-bottom: 3em;
          font-size: 1.25em; }
          section.video-block .video-wrap .video-details .video-message p {
            color: #ffffff; }
        section.video-block .video-wrap .video-details .button {
          margin: 0; }
      section.video-block .video-wrap .video-thumb {
        display: flex;
        justify-content: flex-end;
        position: relative; }
        section.video-block .video-wrap .video-thumb .thumb {
          width: 100%;
          align-self: center; }
          section.video-block .video-wrap .video-thumb .thumb.right {
            border-radius: 20px 0 0 20px; }
          section.video-block .video-wrap .video-thumb .thumb.left {
            border-radius: 0 20px 20px 0; }
        section.video-block .video-wrap .video-thumb .play {
          position: absolute;
          top: -100px;
          background-color: #ffa400;
          border-radius: 50%;
          width: 150px;
          height: 150px;
          z-index: 10;
          transition: background-color 0.2s ease; }
          section.video-block .video-wrap .video-thumb .play:hover {
            background-color: #db423c; }
          section.video-block .video-wrap .video-thumb .play svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 4rem;
            text-align: center;
            color: #ffffff; }
  @media (max-width: 1023px) {
    section.video-block.right .video-wrap, section.video-block.left .video-wrap {
      padding: 2em 0;
      margin: 0; }
      section.video-block.right .video-wrap .video-header, section.video-block.left .video-wrap .video-header {
        position: static;
        display: block; }
        section.video-block.right .video-wrap .video-header h2, section.video-block.left .video-wrap .video-header h2 {
          font-size: 3.5rem;
          margin: 0 1.75rem; }
          section.video-block.right .video-wrap .video-header h2 br, section.video-block.left .video-wrap .video-header h2 br {
            display: none; }
      section.video-block.right .video-wrap .video, section.video-block.left .video-wrap .video {
        margin-top: 2rem; }
        section.video-block.right .video-wrap .video-details, section.video-block.left .video-wrap .video-details {
          margin: 0;
          flex-basis: 100%; }
          section.video-block.right .video-wrap .video-details .video-message, section.video-block.left .video-wrap .video-details .video-message {
            margin: 1em auto; }
          section.video-block.right .video-wrap .video-details .btn, section.video-block.left .video-wrap .video-details .btn {
            display: flex;
            justify-content: center;
            margin-left: 0; }
            section.video-block.right .video-wrap .video-details .btn .button, section.video-block.left .video-wrap .video-details .btn .button {
              width: 40%;
              text-align: center; }
        section.video-block.right .video-wrap .video-thumb, section.video-block.left .video-wrap .video-thumb {
          flex-basis: 100%;
          order: -1; }
          section.video-block.right .video-wrap .video-thumb .thumb.right, section.video-block.right .video-wrap .video-thumb .thumb.left, section.video-block.left .video-wrap .video-thumb .thumb.right, section.video-block.left .video-wrap .video-thumb .thumb.left {
            border-radius: 0px; }
          section.video-block.right .video-wrap .video-thumb .play, section.video-block.left .video-wrap .video-thumb .play {
            width: 70px;
            height: 70px;
            top: -35px;
            right: 50%;
            left: auto;
            margin-right: -35px; }
            section.video-block.right .video-wrap .video-thumb .play svg, section.video-block.left .video-wrap .video-thumb .play svg {
              width: 2rem; }
            section.video-block.right .video-wrap .video-thumb .play i, section.video-block.left .video-wrap .video-thumb .play i {
              font-size: 2rem;
              text-align: center; } }
  @media (max-width: 767px) {
    section.video-block.right .video-wrap .video-header h2, section.video-block.left .video-wrap .video-header h2 {
      font-size: 1.5rem; }
    section.video-block.right .video-wrap .video, section.video-block.left .video-wrap .video {
      margin-top: 3rem; }
      section.video-block.right .video-wrap .video.no-header, section.video-block.left .video-wrap .video.no-header {
        margin-top: 2.5rem; }
      section.video-block.right .video-wrap .video-details .video-message, section.video-block.left .video-wrap .video-details .video-message {
        margin: 0 auto;
        width: 90%;
        font-size: 1em; }
      section.video-block.right .video-wrap .video-details .btn .button, section.video-block.left .video-wrap .video-details .btn .button {
        width: 90%;
        padding: 1em 0; }
      section.video-block.right .video-wrap .video-thumb .play, section.video-block.left .video-wrap .video-thumb .play {
        width: 70px;
        height: 70px;
        top: -35px;
        right: 50%;
        left: auto;
        margin-right: -35px; }
        section.video-block.right .video-wrap .video-thumb .play i, section.video-block.left .video-wrap .video-thumb .play i {
          font-size: 2rem;
          text-align: center; } }

section.news-block {
  background-color: #1f3254; }
  section.news-block.right .news-wrap {
    padding: 4em 0 2em 0;
    margin-left: 6em; }
    section.news-block.right .news-wrap .news-thumb .file {
      right: 15%; }
  section.news-block.left .news-wrap {
    padding: 4em 0 2em 0;
    margin-right: 6em; }
    section.news-block.left .news-wrap .news-header {
      display: flex;
      justify-content: flex-end;
      width: 100%; }
    section.news-block.left .news-wrap .news-thumb .file {
      left: 15%; }
    section.news-block.left .news-wrap .news-details div {
      margin-left: 2em; }
  section.news-block .news-wrap {
    position: relative;
    overflow-x: hidden; }
    section.news-block .news-wrap .news-header {
      position: absolute;
      width: 100%;
      z-index: 10; }
      section.news-block .news-wrap .news-header h2 {
        color: #ffffff;
        font-size: 4.25em; }
    section.news-block .news-wrap .news {
      align-items: center;
      margin-top: 9em; }
      section.news-block .news-wrap .news.no-header {
        margin-top: 2.5em; }
      section.news-block .news-wrap .news-details {
        height: 100%;
        margin-top: 5em; }
        section.news-block .news-wrap .news-details .news-message {
          width: 66%;
          margin-bottom: 3em;
          font-size: 1.25em; }
          section.news-block .news-wrap .news-details .news-message p {
            color: #ffffff; }
        section.news-block .news-wrap .news-details .button {
          margin: 0; }
      section.news-block .news-wrap .news-thumb {
        display: flex;
        justify-content: flex-end;
        position: relative; }
        section.news-block .news-wrap .news-thumb .thumb {
          width: 100%;
          align-self: center; }
          section.news-block .news-wrap .news-thumb .thumb.right {
            border-radius: 20px 0 0 20px; }
          section.news-block .news-wrap .news-thumb .thumb.left {
            border-radius: 0 20px 20px 0; }
        section.news-block .news-wrap .news-thumb .file {
          position: absolute;
          top: -75px;
          background-color: #2b2b2a;
          border-radius: 50%;
          width: 150px;
          height: 150px;
          z-index: 10;
          transition: background-color 0.2s ease;
          color: #ffffff; }
          section.news-block .news-wrap .news-thumb .file svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 4rem;
            text-align: center;
            color: #ffffff; }
  @media (max-width: 1400px) {
    section.news-block.right .news-wrap, section.news-block.left .news-wrap {
      padding: 2em 0; }
      section.news-block.right .news-wrap .news-header, section.news-block.left .news-wrap .news-header {
        position: relative; }
        section.news-block.right .news-wrap .news-header h2, section.news-block.left .news-wrap .news-header h2 {
          margin: 0.5rem 0 2rem 0.5rem;
          font-size: 3.75rem; }
      section.news-block.right .news-wrap .news, section.news-block.left .news-wrap .news {
        margin-top: 0; } }
  @media (max-width: 1023px) {
    section.news-block.right .news-wrap, section.news-block.left .news-wrap {
      padding: 2em 0;
      margin: 0; }
      section.news-block.right .news-wrap .news-header, section.news-block.left .news-wrap .news-header {
        position: static;
        display: block; }
        section.news-block.right .news-wrap .news-header h2, section.news-block.left .news-wrap .news-header h2 {
          font-size: 3.5rem;
          margin: 0 1.75rem; }
          section.news-block.right .news-wrap .news-header h2 br, section.news-block.left .news-wrap .news-header h2 br {
            display: none; }
      section.news-block.right .news-wrap .news, section.news-block.left .news-wrap .news {
        margin-top: 2rem; }
        section.news-block.right .news-wrap .news-details, section.news-block.left .news-wrap .news-details {
          margin: 0;
          flex-basis: 100%; }
          section.news-block.right .news-wrap .news-details .news-message, section.news-block.left .news-wrap .news-details .news-message {
            margin: 1em auto; }
          section.news-block.right .news-wrap .news-details .btn, section.news-block.left .news-wrap .news-details .btn {
            display: flex;
            justify-content: center;
            margin-left: 0; }
            section.news-block.right .news-wrap .news-details .btn .button, section.news-block.left .news-wrap .news-details .btn .button {
              width: 40%;
              text-align: center; }
        section.news-block.right .news-wrap .news-thumb, section.news-block.left .news-wrap .news-thumb {
          flex-basis: 100%;
          order: -1; }
          section.news-block.right .news-wrap .news-thumb .thumb.right, section.news-block.right .news-wrap .news-thumb .thumb.left, section.news-block.left .news-wrap .news-thumb .thumb.right, section.news-block.left .news-wrap .news-thumb .thumb.left {
            border-radius: 0px; }
          section.news-block.right .news-wrap .news-thumb .file, section.news-block.left .news-wrap .news-thumb .file {
            width: 70px;
            height: 70px;
            top: -35px;
            left: 50%;
            transform: translateX(-50%); }
            section.news-block.right .news-wrap .news-thumb .file i, section.news-block.left .news-wrap .news-thumb .file i {
              font-size: 2rem; } }
  @media (max-width: 767px) {
    section.news-block.right .news-wrap .news-header h2, section.news-block.left .news-wrap .news-header h2 {
      font-size: 1.5rem; }
    section.news-block.right .news-wrap .news, section.news-block.left .news-wrap .news {
      margin-top: 3rem; }
      section.news-block.right .news-wrap .news.no-header, section.news-block.left .news-wrap .news.no-header {
        margin-top: 2.5rem; }
      section.news-block.right .news-wrap .news-details .news-message, section.news-block.left .news-wrap .news-details .news-message {
        margin: 0 auto;
        width: 90%;
        font-size: 1em; }
      section.news-block.right .news-wrap .news-details .btn .button, section.news-block.left .news-wrap .news-details .btn .button {
        width: 90%;
        padding: 1em 0; } }

section.twitter-block {
  padding: 6em 0 4em; }
  section.twitter-block .tweets {
    position: relative;
    border: 2px solid #c6c6c6;
    width: 70%;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 10px; }
    section.twitter-block .tweets .fa-twitter {
      position: absolute;
      background-color: #2b2b2a;
      color: #ffffff;
      font-size: 75px;
      padding: 37.5px;
      top: -75px;
      left: -75px;
      border-radius: 100%;
      z-index: 5; }
    section.twitter-block .tweets .tweet-wrapper {
      border-bottom: 2px solid #c6c6c6; }
      section.twitter-block .tweets .tweet-wrapper .slick-list .slick-track {
        display: flex; }
        section.twitter-block .tweets .tweet-wrapper .slick-list .slick-track .slick-slide {
          display: flex;
          height: auto; }
          section.twitter-block .tweets .tweet-wrapper .slick-list .slick-track .slick-slide > div {
            width: 100%; }
            section.twitter-block .tweets .tweet-wrapper .slick-list .slick-track .slick-slide > div .tweet-slide {
              height: 100%;
              width: 100%; }
      section.twitter-block .tweets .tweet-wrapper .tweet-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        font-size: 1.125rem; }
        section.twitter-block .tweets .tweet-wrapper .tweet-block .user {
          display: flex;
          align-items: center;
          padding: 3em 0; }
          section.twitter-block .tweets .tweet-wrapper .tweet-block .user .userphoto {
            border-radius: 100%;
            height: 62px;
            width: 62px;
            float: left; }
          section.twitter-block .tweets .tweet-wrapper .tweet-block .user .user-details {
            padding-left: 1em; }
            section.twitter-block .tweets .tweet-wrapper .tweet-block .user .user-details .name {
              font-size: 1.125em; }
            section.twitter-block .tweets .tweet-wrapper .tweet-block .user .user-details .username a {
              text-decoration: none; }
        section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet {
          width: 50%;
          text-align: center;
          font-size: 1.25em; }
        section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer {
          padding: 6em 0 1em;
          width: 90%;
          margin-top: auto;
          justify-content: space-between; }
          section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer .controls a {
            margin-right: 1em; }
            section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer .controls a.retweet:hover {
              color: #000000; }
            section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer .controls a.like:hover {
              color: #ff0000; }
          section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer div, section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer div > a {
            color: #c6c6c6;
            text-decoration: none; }
            section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer div:hover, section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer div > a:hover {
              color: #7f7f7f; }
    section.twitter-block .tweets .slide-controls {
      height: 25px; }
      section.twitter-block .tweets .slide-controls .dots-container {
        position: relative;
        display: flex;
        justify-content: center;
        width: 290px;
        margin: 0 auto; }
        section.twitter-block .tweets .slide-controls .dots-container .slick-next {
          right: 0; }
        section.twitter-block .tweets .slide-controls .dots-container .slick-prev {
          left: 0; }
        section.twitter-block .tweets .slide-controls .dots-container .slick-next:before, section.twitter-block .tweets .slide-controls .dots-container .slick-prev:before {
          color: #db423c;
          font-family: "Font Awesome 5 Pro"; }
        section.twitter-block .tweets .slide-controls .dots-container .slick-dots {
          bottom: -20px;
          width: auto;
          display: inline-block;
          margin: 0 auto; }
        section.twitter-block .tweets .slide-controls .dots-container .slick-dots li button:before {
          font-size: 18px;
          width: 18px;
          height: 18px;
          line-height: 0; }
  @media (max-width: 1023px) {
    section.twitter-block {
      padding: 3em 0 4em; }
      section.twitter-block .tweets {
        width: 90%; }
        section.twitter-block .tweets i.fa-twitter {
          left: 50%;
          margin-left: -35px;
          font-size: 30px;
          padding: 20px;
          top: -35px; } }
  @media (max-width: 767px) {
    section.twitter-block .tweets .tweet-wrapper .tweet-block {
      font-size: 1rem; }
      section.twitter-block .tweets .tweet-wrapper .tweet-block .user-details .name {
        font-size: 1rem; }
      section.twitter-block .tweets .tweet-wrapper .tweet-block .user-details .username {
        font-size: .875rem; }
      section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet {
        width: 90%;
        font-size: 1em; }
      section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer div, section.twitter-block .tweets .tweet-wrapper .tweet-block .tweet-footer div > a {
        font-size: 1rem; } }

section.button-callout {
  background-color: #384e76;
  position: relative; }
  section.button-callout .float-circle {
    position: absolute; }
    section.button-callout .float-circle.left {
      left: 12%;
      bottom: 50%;
      width: 87px;
      height: 87px; }
    section.button-callout .float-circle.right {
      right: 15%;
      bottom: 60%;
      width: 52px;
      height: 52px; }
      section.button-callout .float-circle.right.top {
        width: 37px;
        height: 37px;
        right: 7%;
        top: -100%; }
    @media (max-width: 1023px) {
      section.button-callout .float-circle {
        display: none; } }
  section.button-callout .callout-inner {
    padding: 0  0 2em 0;
    width: 35%;
    margin: 0 auto; }
    section.button-callout .callout-inner .callout-message {
      color: #ffffff;
      text-align: center;
      font-size: 1.25rem; }
    @media (max-width: 1023px) {
      section.button-callout .callout-inner {
        width: 60%; } }
    @media (max-width: 767px) {
      section.button-callout .callout-inner {
        width: 100%; }
        section.button-callout .callout-inner .callout-message {
          width: 90%;
          margin: 0 auto;
          font-size: 1rem; } }

section.photo-callout {
  height: 58vw;
  padding-top: 6em;
  background-color: #1f3254; }
  section.photo-callout .callout-wrapper {
    height: 100%;
    background-size: cover;
    background-position: center center;
    position: relative; }
    section.photo-callout .callout-wrapper span.icon {
      position: absolute;
      background-color: #2b2b2a;
      color: #ffffff;
      width: 150px;
      height: 150px;
      top: -75px;
      right: 13%;
      border-radius: 50%; }
      section.photo-callout .callout-wrapper span.icon svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        text-align: center; }
    section.photo-callout .callout-wrapper .callout-inner {
      background-color: rgba(0, 0, 0, 0.35);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      section.photo-callout .callout-wrapper .callout-inner .callout-header h2 {
        font-size: 4.25em;
        color: #ffffff;
        text-align: center;
        margin: 0 1rem; }
      section.photo-callout .callout-wrapper .callout-inner .callout-message {
        margin: 2em auto;
        width: 37.5%; }
      section.photo-callout .callout-wrapper .callout-inner .callout-message,
      section.photo-callout .callout-wrapper .callout-inner .callout-message > p {
        color: #ffffff;
        font-size: 1.25rem;
        text-align: center; }
  @media (max-width: 1023px) {
    section.photo-callout {
      padding-top: 3em;
      height: auto; }
      section.photo-callout .callout-wrapper span.icon {
        width: 70px;
        height: 70px;
        top: -35px;
        left: 50%;
        transform: translateX(-50%); }
        section.photo-callout .callout-wrapper span.icon i {
          font-size: 2rem; }
      section.photo-callout .callout-wrapper .callout-inner {
        padding: 4em 0; }
        section.photo-callout .callout-wrapper .callout-inner .callout-header h2 {
          font-size: 3.5rem; }
        section.photo-callout .callout-wrapper .callout-inner .callout-message {
          margin: 2em auto;
          width: 70%; }
        section.photo-callout .callout-wrapper .callout-inner .callout-message,
        section.photo-callout .callout-wrapper .callout-inner .callout-message > p {
          color: #ffffff;
          font-size: 1.25rem;
          text-align: center; } }
  @media (max-width: 767px) {
    section.photo-callout .callout-wrapper .callout-inner .callout-header h2 {
      font-size: 1.5rem; }
    section.photo-callout .callout-wrapper .callout-inner .callout-message {
      margin: 2em auto;
      width: 90%; }
    section.photo-callout .callout-wrapper .callout-inner .callout-message,
    section.photo-callout .callout-wrapper .callout-inner .callout-message > p {
      font-size: 1rem; } }

section.share-block {
  background-color: #eef0f4; }
  section.share-block h2 {
    margin: 0 0 2rem;
    padding-top: 4vw;
    text-align: center;
    font-size: 4.25rem; }
  section.share-block .share-message {
    font-size: 1.5rem;
    text-align: center;
    width: 55%;
    margin: 0 auto; }
  section.share-block .share-grid {
    width: 85%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 6vw 0 16rem; }
    section.share-block .share-grid .share-img-block {
      position: relative;
      background-color: #d8d8d8;
      height: 20vw;
      width: 20vw;
      outline: 7px solid transparent;
      transition: outline 0.2s ease; }
      section.share-block .share-grid .share-img-block img {
        width: 100%;
        height: 100%;
        vertical-align: bottom; }
      section.share-block .share-grid .share-img-block::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -25px;
        width: 0;
        height: 0;
        border-top: solid 25px transparent;
        border-left: solid 25px transparent;
        border-right: solid 25px transparent;
        transition: all 0.2s ease; }
      section.share-block .share-grid .share-img-block.hover {
        outline: 7px solid black; }
        section.share-block .share-grid .share-img-block.hover::after {
          border-top: solid 25px black; }
      section.share-block .share-grid .share-img-block:first-of-type .share-block {
        left: -7px; }
      section.share-block .share-grid .share-img-block:nth-of-type(2) .share-block, section.share-block .share-grid .share-img-block:nth-of-type(3) .share-block {
        left: 50%;
        margin-left: -361.5px; }
      section.share-block .share-grid .share-img-block:last-of-type .share-block {
        right: -7px; }
      section.share-block .share-grid .share-img-block .share-block {
        background-color: #ffffff;
        display: none;
        position: absolute;
        bottom: -160px;
        padding: 0 2rem; }
        section.share-block .share-grid .share-img-block .share-block.active {
          display: flex; }
        section.share-block .share-grid .share-img-block .share-block .share-button {
          display: inline-block;
          padding: 0.5rem 5rem;
          margin: 2rem 1rem;
          border-radius: 5px;
          transition: background-color 0.2s ease; }
          section.share-block .share-grid .share-img-block .share-block .share-button i {
            color: #ffffff;
            font-size: 2rem; }
          section.share-block .share-grid .share-img-block .share-block .share-button svg {
            width: 2rem;
            height: auto; }
          section.share-block .share-grid .share-img-block .share-block .share-button.tw {
            background-color: #929292; }
            section.share-block .share-grid .share-img-block .share-block .share-button.tw:hover {
              background-color: #000000; }
          section.share-block .share-grid .share-img-block .share-block .share-button.fb {
            background-color: #666666; }
            section.share-block .share-grid .share-img-block .share-block .share-button.fb:hover {
              background-color: #3b5998; }
          section.share-block .share-grid .share-img-block .share-block .share-button.pin {
            background-color: #515151; }
            section.share-block .share-grid .share-img-block .share-block .share-button.pin:hover {
              background-color: #bd081c; }
      @media (max-width: 1023px) {
        section.share-block .share-grid .share-img-block {
          height: 40vw;
          width: 40vw; }
          section.share-block .share-grid .share-img-block:first-of-type {
            margin-bottom: 22vw; }
          section.share-block .share-grid .share-img-block:nth-of-type(odd) .share-block {
            left: -7px;
            right: auto; }
          section.share-block .share-grid .share-img-block:nth-of-type(even) .share-block {
            right: -7px;
            left: auto; }
          section.share-block .share-grid .share-img-block:nth-of-type(3) .share-block {
            top: -160px;
            bottom: auto;
            left: 353.5px; }
          section.share-block .share-grid .share-img-block:nth-of-type(3).hover::after {
            bottom: 100%;
            top: auto;
            border-bottom: solid 25px black;
            border-top: 0; }
          section.share-block .share-grid .share-img-block:nth-of-type(4) .share-block {
            top: -160px;
            bottom: auto; }
          section.share-block .share-grid .share-img-block:nth-of-type(4).hover::after {
            bottom: 100%;
            top: auto;
            border-bottom: solid 25px black;
            border-top: 0; }
          section.share-block .share-grid .share-img-block .share-block {
            width: 85vw;
            padding: 0;
            justify-content: space-around; } }
      @media (max-width: 767px) {
        section.share-block .share-grid .share-img-block:first-of-type {
          margin-bottom: 172px; }
          section.share-block .share-grid .share-img-block:first-of-type .share-block {
            bottom: -127px; }
        section.share-block .share-grid .share-img-block:nth-of-type(2) .share-block {
          bottom: -127px; }
        section.share-block .share-grid .share-img-block:nth-of-type(3) .share-block {
          top: -127px; }
        section.share-block .share-grid .share-img-block:nth-of-type(3).hover::after {
          bottom: 100%;
          top: auto;
          border-bottom: solid 25px black;
          border-top: 0; }
        section.share-block .share-grid .share-img-block:nth-of-type(4) .share-block {
          top: -127px; }
        section.share-block .share-grid .share-img-block:nth-of-type(4).hover::after {
          bottom: 100%;
          top: auto;
          border-bottom: solid 25px black;
          border-top: 0; }
        section.share-block .share-grid .share-img-block .share-block {
          width: 85vw;
          padding: 0;
          justify-content: space-around; }
          section.share-block .share-grid .share-img-block .share-block .share-button {
            padding: 0.5rem 0;
            width: 25%;
            text-align: center;
            margin: 1rem 0; }
            section.share-block .share-grid .share-img-block .share-block .share-button i {
              font-size: 1rem; } }
  @media (max-width: 1023px) {
    section.share-block h2 {
      font-size: 3.5rem; }
    section.share-block .share-message {
      font-size: 1rem;
      width: 85%; }
    section.share-block .share-grid {
      padding: 2.5rem 0; } }
  @media (max-width: 767px) {
    section.share-block h2 {
      font-size: 1.5rem; } }

section.rotary-block .rotary-heading {
  width: 90%;
  margin: 0 auto;
  padding: 3rem 0 0; }
  section.rotary-block .rotary-heading h2 {
    font-size: 4.25rem;
    text-align: center;
    margin: 0; }
  section.rotary-block .rotary-heading .message {
    font-size: 1.25rem;
    text-align: left;
    margin: 1.5rem auto 0;
    width: 65%; }
    section.rotary-block .rotary-heading .message a {
      color: #db423c;
      text-decoration: none;
      font-style: initial; }
      section.rotary-block .rotary-heading .message a:hover {
        color: #ba211a; }

section.rotary-block .rotary-inner .rotary-wheel .wheel {
  border-radius: 100%;
  border: 3px solid #595a58;
  width: 41.5vw;
  height: 41.5vw;
  margin: 8vw 8vw;
  position: relative; }
  section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon {
    position: absolute;
    margin-left: -5.35vw;
    border-radius: 100%; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon img {
      padding: 2.5vw;
      border-radius: 100%;
      border: 3px solid #595a58;
      width: 5.7vw;
      height: 5.7vw;
      background-color: #ffffff; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:hover img {
      border-color: #ffa400; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon.active img {
      border-color: #db423c; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:first-of-type {
      top: -5.35vw;
      left: 50%; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(2) {
      top: 20%;
      left: 98%; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(3) {
      top: 75%;
      left: 80%; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(4) {
      top: 75%;
      left: 20%; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(5) {
      top: 20%;
      left: 2%; }

section.rotary-block .rotary-inner .rotary-info {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  section.rotary-block .rotary-inner .rotary-info .info-block {
    display: none; }
    section.rotary-block .rotary-inner .rotary-info .info-block.active {
      display: block; }
    section.rotary-block .rotary-inner .rotary-info .info-block h3 {
      font-size: 4.25rem;
      font-family: "Merriweather", serif;
      margin: 0 0 .5rem; }
    section.rotary-block .rotary-inner .rotary-info .info-block .message {
      font-size: 1.375rem; }
  section.rotary-block .rotary-inner .rotary-info .rotary-controls {
    margin: 2rem 0; }
    section.rotary-block .rotary-inner .rotary-info .rotary-controls i {
      font-size: 3.375rem;
      color: #db423c;
      cursor: pointer; }
      section.rotary-block .rotary-inner .rotary-info .rotary-controls i:first-of-type {
        margin: 0 2rem 0 0; }
      section.rotary-block .rotary-inner .rotary-info .rotary-controls i:hover {
        color: #ba211a; }

@media (max-width: 991px) {
  section.rotary-block .rotary-inner .rotary-info .info-block h3 {
    font-size: 3.5rem; } }

@media (max-width: 1023px) {
  section.rotary-block .rotary-heading h2 {
    font-size: 3.5rem; }
  section.rotary-block .rotary-heading .message {
    font-size: 1rem;
    width: 100%; } }

@media (max-width: 767px) {
  section.rotary-block .rotary-heading {
    width: 90%;
    margin: 1rem auto 0;
    padding: 2rem 0 0; }
    section.rotary-block .rotary-heading h2 {
      font-size: 1.5rem; }
    section.rotary-block .rotary-heading .message {
      font-size: 1rem;
      margin-bottom: 1rem; }
  section.rotary-block .rotary-inner .rotary-wheel {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin: 12vw 0 20vw; }
    section.rotary-block .rotary-inner .rotary-wheel .wheel {
      width: 71.5vw;
      height: 71.5vw;
      margin: 0; }
      section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon {
        margin-left: -11.8vw; }
        section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon img {
          padding: 5vw;
          width: 12vw;
          height: 12vw; }
        section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:first-of-type {
          top: -7.8vw;
          left: 50%; }
        section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(2) {
          top: 18%;
          left: 94%; }
        section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(3) {
          top: 75%;
          left: 80%; }
        section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(4) {
          top: 75%;
          left: 20%; }
        section.rotary-block .rotary-inner .rotary-wheel .wheel .rotary-icon:nth-of-type(5) {
          top: 18%;
          left: 6%; }
  section.rotary-block .rotary-inner .rotary-info {
    margin: 2rem 5% 0;
    flex-basis: 100%;
    order: -1; }
    section.rotary-block .rotary-inner .rotary-info .info-wrapper .info-block h3 {
      font-size: 1.5rem; }
    section.rotary-block .rotary-inner .rotary-info .info-wrapper .info-block .message {
      font-size: 1rem; }
    section.rotary-block .rotary-inner .rotary-info .rotary-controls {
      margin: 2rem 0 0; }
      section.rotary-block .rotary-inner .rotary-info .rotary-controls i {
        font-size: 2.25rem; } }

section.picture-grid.bg-w {
  background-color: #ffffff; }

section.picture-grid.bg-g {
  background-color: #eef0f4; }

section.picture-grid .grid-inner {
  width: 90%;
  margin: 0 auto;
  padding: 3rem 0; }
  section.picture-grid .grid-inner h2 {
    font-size: 4.25rem;
    text-align: center;
    margin: 0; }
  section.picture-grid .grid-inner .message {
    font-size: 1.25rem;
    text-align: center;
    margin: 1.5rem 0; }
  section.picture-grid .grid-inner .pics .pic-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem; }
    section.picture-grid .grid-inner .pics .pic-block a {
      border-radius: 100%; }
      section.picture-grid .grid-inner .pics .pic-block a .picture-wrap {
        background-color: #d8d8d8;
        width: 19.5vw;
        height: 19.5vw;
        border-radius: 100%;
        position: relative; }
        section.picture-grid .grid-inner .pics .pic-block a .picture-wrap img {
          border-radius: 100%;
          background-color: #d8d8d8;
          width: 100%;
          height: 100%; }
          section.picture-grid .grid-inner .pics .pic-block a .picture-wrap img.add {
            width: 60%;
            height: auto;
            padding: 19%;
            border: 3px solid #595a58;
            background-color: #ffffff;
            margin: auto auto; }
        section.picture-grid .grid-inner .pics .pic-block a .picture-wrap svg {
          font-size: 1.875rem;
          width: 1.875rem;
          text-align: center;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 1.5rem;
          border-radius: 100%;
          color: #ffffff;
          transition: all 0.2s ease; }
          section.picture-grid .grid-inner .pics .pic-block a .picture-wrap svg.play {
            background: #db423c; }
          section.picture-grid .grid-inner .pics .pic-block a .picture-wrap svg.file {
            background-color: #2b2b2a; }
      section.picture-grid .grid-inner .pics .pic-block a:hover .picture-wrap i {
        background-color: #ffa400; }
    section.picture-grid .grid-inner .pics .pic-block .name {
      font-size: 2.25rem;
      margin: 0.5rem;
      text-align: center; }
    section.picture-grid .grid-inner .pics .pic-block .title {
      font-size: 1.0625rem;
      text-align: center;
      font-family: "Merriweather", serif;
      width: 75%; }

@media (max-width: 1023px) {
  section.picture-grid .grid-inner h2 {
    font-size: 3.5rem; }
  section.picture-grid .grid-inner .message {
    font-size: 1rem; }
  section.picture-grid .grid-inner .pics .pic-block a {
    border-radius: 100%; }
    section.picture-grid .grid-inner .pics .pic-block a .picture-wrap i {
      font-size: 1.25rem;
      width: 1.25rem;
      text-align: center;
      padding: 1rem; }
  section.picture-grid .grid-inner .pics .pic-block .name {
    margin: 0.5rem 2rem;
    font-weight: 400; } }

@media (max-width: 767px) {
  section.picture-grid .grid-inner {
    width: 90%;
    margin: 3rem auto; }
    section.picture-grid .grid-inner h2 {
      font-size: 1.5rem; }
    section.picture-grid .grid-inner .message {
      font-size: 1rem;
      margin-bottom: 4rem; }
    section.picture-grid .grid-inner .pics .pic-block {
      flex-basis: 100%; }
      section.picture-grid .grid-inner .pics .pic-block a .picture-wrap {
        width: 72.5vw;
        height: 72.5vw; }
        section.picture-grid .grid-inner .pics .pic-block a .picture-wrap img.add {
          width: 60%;
          padding: 19%;
          border: 3px solid #595a58;
          background-color: #ffffff;
          margin: auto auto; }
        section.picture-grid .grid-inner .pics .pic-block a .picture-wrap i {
          font-size: 5.3vw;
          width: 5.3vw;
          text-align: center;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 6.5vw;
          border-radius: 100%;
          color: #ffffff; }
          section.picture-grid .grid-inner .pics .pic-block a .picture-wrap i.play {
            background: #db423c; }
          section.picture-grid .grid-inner .pics .pic-block a .picture-wrap i.file {
            background-color: #2b2b2a; }
      section.picture-grid .grid-inner .pics .pic-block a:hover .picture-wrap i {
        background-color: #ffa400; }
      section.picture-grid .grid-inner .pics .pic-block .name {
        font-size: 2.25rem;
        margin: 0.5rem;
        text-align: center; }
      section.picture-grid .grid-inner .pics .pic-block .title {
        font-size: 1.0625rem;
        text-align: center;
        font-family: "Merriweather", serif;
        width: 75%; } }

section.design-block .design-inner {
  width: 60%;
  margin: 0 auto;
  text-align: center; }
  @media (max-width: 1023px) {
    section.design-block .design-inner {
      width: 90%; } }
  section.design-block .design-inner h2 {
    font-size: 4.25rem; }
  section.design-block .design-inner .message {
    font-size: 1.25rem;
    font-weight: 400;
    width: 90%; }
  section.design-block .design-inner .network-selector {
    margin: 3rem 0;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      section.design-block .design-inner .network-selector {
        margin: 2rem 0; } }
    section.design-block .design-inner .network-selector .network-icon {
      position: relative;
      cursor: pointer;
      max-width: 170px;
      width: 12vw;
      height: 12vw;
      max-height: 170px;
      border: 10px solid transparent;
      border-radius: 100%;
      background-color: transparent;
      transition: all .2s ease; }
      section.design-block .design-inner .network-selector .network-icon img {
        width: 100%; }
      section.design-block .design-inner .network-selector .network-icon:hover {
        background-color: #494949;
        border: 10px solid #494949; }
      section.design-block .design-inner .network-selector .network-icon.active {
        border: 10px solid #494949; }
        section.design-block .design-inner .network-selector .network-icon.active img {
          filter: brightness(0) invert(1); }
        section.design-block .design-inner .network-selector .network-icon.active[data-canvas-selector="fb"] {
          background-color: #3b5998; }
        section.design-block .design-inner .network-selector .network-icon.active[data-canvas-selector="tw"] {
          background-color: #000000; }
        section.design-block .design-inner .network-selector .network-icon.active[data-canvas-selector="pin"] {
          background-color: #bd081c; }
        section.design-block .design-inner .network-selector .network-icon.active[data-canvas-selector="ig"] {
          background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%); }
        section.design-block .design-inner .network-selector .network-icon.active::after {
          content: '';
          position: absolute;
          top: 103%;
          left: 50%;
          margin-left: -25px;
          width: 0;
          height: 0;
          border-top: solid 25px #494949;
          border-left: solid 25px transparent;
          border-right: solid 25px transparent; }
          @media (max-width: 767px) {
            section.design-block .design-inner .network-selector .network-icon.active::after {
              margin-left: -22px;
              border-top: solid 22px #494949;
              border-left: solid 22px transparent;
              border-right: solid 22px transparent; } }

.design-frames {
  padding: 3em 0; }
  @media (max-width: 1023px) {
    .design-frames {
      padding: 1em 0; } }
  .design-frames [data-canvas-placeholder="profile"] {
    width: 360px;
    height: auto; }
  .design-frames .design-frames-inner {
    display: flex; }
    @media (max-width: 1023px) {
      .design-frames .design-frames-inner {
        display: block; } }
  .design-frames .profile-frame, .design-frames .header-frame {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 50%; }
    .design-frames .profile-frame .canvas-column, .design-frames .header-frame .canvas-column {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      flex-direction: column; }
    .design-frames .profile-frame .input-column, .design-frames .header-frame .input-column {
      margin-top: 2em; }
  .design-frames .canvas-wrapper {
    position: relative;
    display: block;
    margin: 0 auto;
    overflow: hidden; }
    .design-frames .canvas-wrapper.has-image .toolbar {
      display: block;
      width: 100%; }
    .design-frames .canvas-wrapper.profile-canvas {
      width: 220px;
      height: 220px;
      background: #c6c6c6;
      border-radius: 50%; }
      .design-frames .canvas-wrapper.profile-canvas img {
        height: 100%;
        max-width: none; }
      .design-frames .canvas-wrapper.profile-canvas .toolbar {
        bottom: .5em; }
    .design-frames .canvas-wrapper.header-canvas {
      position: relative;
      width: 100%; }
      @media (max-width: 1023px) {
        .design-frames .canvas-wrapper.header-canvas {
          margin-top: 2em; } }
      .design-frames .canvas-wrapper.header-canvas img {
        height: auto;
        max-width: 100%; }
    .design-frames .canvas-wrapper .toolbar {
      display: none;
      position: absolute;
      bottom: 2em;
      left: 50%;
      transform: translateX(-50%); }
      .design-frames .canvas-wrapper .toolbar button {
        color: #ffffff;
        background: #425d8c;
        border: none;
        border-radius: 6px;
        font-size: .75em;
        padding: .5em;
        letter-spacing: 1px;
        font-family: "Libre Franklin", sans-serif;
        cursor: pointer; }
  .design-frames .header-frame {
    display: none; }
    .design-frames .header-frame.active {
      display: block; }
  .design-frames .canvas-thumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .5em 0; }
    @media (max-width: 1023px) {
      .design-frames .canvas-thumbs {
        flex-wrap: wrap;
        margin: 0 -.5em; }
        .design-frames .canvas-thumbs p {
          flex: 1 0 100%; } }
    .design-frames .canvas-thumbs .canvas-thumb {
      max-width: 200px;
      margin: 0 .5em;
      opacity: 0.5;
      transition: all .3s ease; }
      .design-frames .canvas-thumbs .canvas-thumb.active {
        opacity: 1; }
      .design-frames .canvas-thumbs .canvas-thumb img {
        max-width: 100%;
        height: auto;
        vertical-align: middle; }
      @media (max-width: 1023px) {
        .design-frames .canvas-thumbs .canvas-thumb {
          max-width: calc(50% - 1em); } }

section.story-form .form-wrapper {
  width: 56%;
  margin: 0 auto;
  text-align: center; }
  section.story-form .form-wrapper h2 {
    font-size: 4.25rem; }
  section.story-form .form-wrapper form {
    text-align: left;
    margin: 4em 0; }
  section.story-form .form-wrapper .message {
    font-size: 1.25rem; }
  @media (max-width: 1023px) {
    section.story-form .form-wrapper {
      width: 80%; }
      section.story-form .form-wrapper h2 {
        font-size: 2.5rem; } }
  @media (max-width: 767px) {
    section.story-form .form-wrapper h2 {
      font-size: 1.5rem; }
    section.story-form .form-wrapper .message {
      font-size: 1rem; }
    section.story-form .form-wrapper form input, section.story-form .form-wrapper form select, section.story-form .form-wrapper form textarea {
      font-size: .825rem; }
      section.story-form .form-wrapper form input::placeholder, section.story-form .form-wrapper form select::placeholder, section.story-form .form-wrapper form textarea::placeholder {
        font-size: .825rem; }
    section.story-form .form-wrapper form select {
      height: 44px; }
    section.story-form .form-wrapper form div.checkbox {
      font-size: 1rem; } }

section.body-block {
  font-family: "Merriweather", serif;
  width: 70%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 0; }
  section.body-block h1 {
    font-family: Merriweather,serif;
    font-size: 3.5em;
    color: #2b2b2a;
    margin: 1rem 0; }
  section.body-block h2 {
    font-size: 2.25em;
    margin: 1rem 0; }
  section.body-block pre {
    white-space: pre-wrap;
    background-color: #eef0f4;
    padding: 1.25rem; }
  section.body-block blockquote {
    border-left: 4px solid #ffa400;
    padding: .5rem 0 .5rem 1rem; }
  section.body-block .full-body, section.body-block .flex-block {
    line-height: 1.75; }
    section.body-block .full-body p, section.body-block .full-body a, section.body-block .full-body ul, section.body-block .full-body ol, section.body-block .flex-block p, section.body-block .flex-block a, section.body-block .flex-block ul, section.body-block .flex-block ol {
      font-family: "Merriweather", serif;
      line-height: 2.125rem; }
      @media (max-width: 767px) {
        section.body-block .full-body p, section.body-block .full-body a, section.body-block .full-body ul, section.body-block .full-body ol, section.body-block .flex-block p, section.body-block .flex-block a, section.body-block .flex-block ul, section.body-block .flex-block ol {
          line-height: 1.625rem; } }
    section.body-block .full-body a, section.body-block .flex-block a {
      color: #db423c;
      text-decoration: none;
      font-style: initial; }
      section.body-block .full-body a:hover, section.body-block .flex-block a:hover {
        color: #ba211a; }
  section.body-block .flex {
    justify-content: space-between; }
    section.body-block .flex .flex-block {
      flex-basis: 48%;
      margin-bottom: 2rem; }
  @media (max-width: 1023px) {
    section.body-block h1 {
      font-size: 2.75em; }
    section.body-block h2 {
      font-size: 1.75em; } }
  @media (max-width: 767px) {
    section.body-block {
      width: 85%; }
      section.body-block h1 {
        font-size: 1.75em; }
      section.body-block h2 {
        font-size: 1.25em; }
      section.body-block .flex .flex-block {
        flex-basis: 100%; } }

.story-form-modal input, .story-form-modal select, .story-form-modal textarea, .system-form-modal input, .system-form-modal select, .system-form-modal textarea {
  width: 100%; }

.story-form-modal h2, .system-form-modal h2 {
  margin: 0;
  font-size: 3rem; }

.subscribe-form-modal h3 {
  margin: 0;
  font-size: 3rem; }

.subscribe-form-modal .form-success {
  color: #2b2b2a; }

.subscribe-form-modal .form-note {
  margin: 1em 0; }

form input, form select, form textarea {
  display: block;
  width: 89%;
  margin: 2.5rem 0;
  font-size: 1.25rem;
  padding: 1.125em 5%;
  border: 1px solid #c6c6c6;
  -ms-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  form input::placeholder, form select::placeholder, form textarea::placeholder {
    font-size: 1.25rem;
    color: #7f7f7f; }

form select {
  border-radius: 0 !important;
  height: 72px;
  background-color: #ffffff;
  background-image: url("/img/field.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  color: #7f7f7f;
  padding: 0 5%;
  -webkit-appearance: none;
  -moz-appearance: none; }
  form select::-ms-expand {
    display: none;
    /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */ }

form input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer; }
  form input[type="checkbox"] ~ .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: solid 3px #c6c6c6;
    border-radius: 4px; }
  form input[type="checkbox"]:checked ~ .checkbox {
    background-color: #db423c;
    border-color: #db423c; }

form input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }
  form input[type="file"]:focus + label {
    outline: 1px dotted #2b2b2a;
    outline: -webkit-focus-ring-color auto 5px; }
  form input[type="file"] + label {
    padding: .5em 2em;
    font-size: 1.25rem;
    color: #ffffff;
    background-color: #db423c;
    border-radius: 30px;
    display: inline-block;
    cursor: pointer; }
    form input[type="file"] + label:hover {
      background-color: #ba211a; }

.file-previews {
  display: inline-block;
  margin: 1em 0;
  cursor: auto; }

.file-preview {
  display: inline-block;
  margin: .5em;
  padding: .5em 1em;
  color: #ffffff;
  background-color: #db423c;
  border-radius: 15px;
  cursor: auto; }

.file-preview-remove-btn {
  margin-left: 8px;
  color: #ffffff;
  cursor: pointer; }

.form-column {
  position: relative; }
  .form-column.checkbox {
    margin-bottom: 2em; }
    .form-column.checkbox label {
      position: relative;
      padding-left: 40px;
      padding-top: 2px;
      cursor: pointer;
      font-family: "Libre Franklin", sans-serif;
      font-size: 1.25rem;
      color: #7f7f7f; }

.form-field.ff-has-errors {
  border-color: #db423c; }

.form-note {
  font-size: 1.25rem; }

.form-instructions {
  font-family: "Libre Franklin", sans-serif;
  margin: .25em 0; }

.form-success {
  color: #ffffff; }

.form-error {
  color: #db423c; }

.field-error {
  font-family: "Libre Franklin", sans-serif;
  color: #db423c; }

p.ff-form-success {
  font-family: "Libre Franklin", sans-serif;
  font-size: 1.5rem;
  font-weight: bold; }

p.ff-form-error {
  font-family: "Libre Franklin", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #db423c; }

div.ff-form-errors p {
  margin: 1em 0;
  font-family: "Libre Franklin", sans-serif;
  color: #db423c; }

ul.ff-errors {
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  margin: 2px 0 0 0;
  padding: 0; }
  ul.ff-errors li {
    font-family: "Libre Franklin", sans-serif;
    color: #db423c; }

#mc_embed_signup form {
  margin: 0 !important; }

#mc_embed_signup_scroll h2 {
  color: #fff; }

#mc_embed_signup_scroll label {
  color: #fff; }

#mc_embed_signup_scroll .mc-field-group {
  height: 80px;
  margin: 38px 0; }
  #mc_embed_signup_scroll .mc-field-group input {
    padding: 1.125em 5% !important;
    width: 93% !important; }

#mc_embed_signup_scroll .indicates-required {
  color: #fff; }

#mc_embed_signup_scroll input {
  margin: 0 !important; }

#mc_embed_signup_scroll .sub-button {
  padding: 1em 2em;
  margin: 18px !important;
  font-size: 1.125rem;
  font-weight: 400;
  border-radius: 30px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  transition: all .2s ease;
  border: 0;
  background-color: #db423c;
  color: #ffffff !important; }
  #mc_embed_signup_scroll .sub-button:hover {
    background-color: #ba211a; }

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  background-color: transparent !important; }

.header.dark {
  background-color: #1f3254; }
  .header.dark .menu-right-item.social-block a i {
    color: #1f3254; }

.header.bg {
  background-size: cover !important;
  background-position: center top !important; }

.header.black {
  background-color: #494949; }

.header.light {
  background-color: #5788e4; }

.header.text-dark .header-message {
  color: #2b2b2a; }

.header.text-white .header-message {
  color: #ffffff; }
  .header.text-white .header-message h1 {
    color: #ffffff; }
  .header.text-white .header-message p {
    color: #ffffff; }

.header .mobile-menu-bar {
  display: none; }
  .header .mobile-menu-bar .mobile-logo {
    margin: 1.75em 1em; }
    .header .mobile-menu-bar .mobile-logo a img {
      width: 210px; }
      .header .mobile-menu-bar .mobile-logo a img:hover {
        filter: invert(0.475) sepia(9) saturate(5) hue-rotate(2deg); }
  .header .mobile-menu-bar .mobile-menu i {
    color: #ffffff;
    font-size: 1.5em;
    margin: 1em; }

.header .menu {
  padding: 2em 0; }
  .header .menu-left {
    justify-content: flex-start;
    align-items: center; }
    .header .menu-left-item {
      margin: 0 .75em; }
      .header .menu-left-item a {
        font-family: "Oswald", sans-serif;
        color: #ffffff;
        text-decoration: none;
        font-weight: 300;
        font-size: 1.5em;
        transition: color .2s ease; }
        .header .menu-left-item a:hover, .header .menu-left-item a.active {
          color: #ffa400; }
        @media (max-width: 1200px) {
          .header .menu-left-item a {
            font-size: 1.25em; } }
  .header .menu-right {
    justify-content: flex-end;
    align-items: center; }
    .header .menu-right-item {
      margin: 0 .75em; }
      .header .menu-right-item a {
        font-family: "Oswald", sans-serif;
        color: #ffffff;
        text-decoration: none;
        font-weight: 300;
        font-size: 1.125em;
        transition: color .2s ease;
        display: flex;
        align-items: center; }
        .header .menu-right-item a:hover {
          color: #ffa400; }
        @media (max-width: 1200px) {
          .header .menu-right-item a {
            font-size: .85em; } }
        .header .menu-right-item a i {
          padding-left: .5rem;
          font-size: .65em; }
      .header .menu-right-item.social-block a {
        width: 25px;
        height: 25px;
        position: relative;
        margin: 0 3px;
        display: inline; }
        .header .menu-right-item.social-block a img {
          vertical-align: baseline;
          padding-top: .25em;
          -webkit-transition: -webkit-filter .2s linear; }
          .header .menu-right-item.social-block a img:hover {
            filter: invert(0.475) sepia(9) saturate(5) hue-rotate(2deg); }

.header .logo {
  width: 40vw;
  margin: 0 auto 55px; }
  .header .logo img {
    margin-bottom: 55px;
    width: 100%;
    -webkit-transition: -webkit-filter .2s linear; }
    .header .logo img:hover {
      filter: invert(0.475) sepia(9) saturate(5) hue-rotate(2deg); }
  .header .logo hr {
    width: 100px;
    margin: 0 auto;
    border: 2px solid #ffffff; }

.header .header-message {
  text-align: center;
  margin: 2em 0 0;
  padding-bottom: 2em; }
  .header .header-message.w-board h1 {
    font-size: 3rem; }
  .header .header-message.w-board p {
    font-size: 1.75rem; }
  .header .header-message.gap h1 {
    margin: .83em 8rem; }
  .header .header-message h1 {
    font-size: 4.25rem;
    margin: .83em 8rem 0;
    font-family: "Merriweather", serif; }
  .header .header-message p {
    font-size: 1.375em;
    width: 50%;
    margin: 3.3rem auto 0; }
  .header .header-message .button {
    margin-top: 3rem; }

.header section.img-board {
  position: relative;
  height: 725px;
  margin-top: 2em; }
  .header section.img-board .mood-modal {
    padding: 20px;
    position: absolute;
    cursor: pointer; }
    .header section.img-board .mood-modal:hover .img, .header section.img-board .mood-modal:hover .plus, .header section.img-board .mood-modal:hover .people-icon {
      box-shadow: 0 0 0 5px #172847;
      border-radius: 100%; }
    .header section.img-board .mood-modal .img {
      transition: width 1s, height 1s;
      transition-delay: 2s; }
    .header section.img-board .mood-modal .blockquote {
      position: relative;
      display: none;
      color: #ffffff;
      font-family: "Merriweather", serif; }
      .header section.img-board .mood-modal .blockquote h3 {
        font-size: 2.25rem; }
      @media (max-width: 1023px) {
        .header section.img-board .mood-modal .blockquote h3 {
          font-size: 1.75rem; } }
      @media (max-width: 767px) {
        .header section.img-board .mood-modal .blockquote h3 {
          font-size: 1.25rem; } }
      .header section.img-board .mood-modal .blockquote:before {
        content: '“';
        content: open-quote;
        position: absolute;
        top: -2rem;
        left: 0;
        color: #ffffff;
        font-family: "Merriweather", serif;
        font-size: 3rem; }
    .header section.img-board .mood-modal .button {
      display: none; }
    .header section.img-board .mood-modal .people-icon {
      display: none; }
    .header section.img-board .mood-modal.shrink .img {
      width: 0px !important;
      height: 0px !important; }
    .header section.img-board .mood-modal .heading {
      margin: 1rem 0; }
    .header section.img-board .mood-modal.modal1 {
      left: 5%;
      top: -5%;
      border-radius: 100px 10px 10px 10px; }
      .header section.img-board .mood-modal.modal1 .heading {
        float: right;
        width: 50%; }
      .header section.img-board .mood-modal.modal1 .message {
        clear: both;
        padding: 1em 0; }
      .header section.img-board .mood-modal.modal1 .img {
        width: 240px;
        height: 240px;
        float: left; }
    .header section.img-board .mood-modal.modal2 {
      left: 10%;
      bottom: 20%;
      border-radius: 10px 10px 10px 120px; }
      .header section.img-board .mood-modal.modal2 .message {
        margin: 0 0 2em; }
      .header section.img-board .mood-modal.modal2 .img {
        width: 258px;
        height: 258px;
        float: left; }
    .header section.img-board .mood-modal.modal3 {
      left: 38%;
      top: 20%;
      border-radius: 100px 10px 10px 10px; }
      .header section.img-board .mood-modal.modal3 .heading {
        float: right;
        width: 50%; }
      .header section.img-board .mood-modal.modal3 .message {
        clear: both;
        padding: 1em 0; }
      .header section.img-board .mood-modal.modal3 .img {
        width: 227px;
        height: 227px;
        float: left; }
    .header section.img-board .mood-modal.modal4 {
      left: 35%;
      bottom: 5%;
      border-radius: 10px 10px 10px 90px; }
      .header section.img-board .mood-modal.modal4 .message {
        margin: 0 0 2em; }
      .header section.img-board .mood-modal.modal4 .control-block .control-row {
        padding-bottom: .5em; }
      .header section.img-board .mood-modal.modal4 .img {
        width: 191px;
        height: 191px;
        float: left; }
    .header section.img-board .mood-modal.modal5 {
      right: 30%;
      bottom: 35%;
      border-radius: 10px 10px 80px 10px; }
      .header section.img-board .mood-modal.modal5 .control-block .control-row {
        padding-bottom: .5em; }
      .header section.img-board .mood-modal.modal5 .message {
        margin: 0 0 2em; }
      .header section.img-board .mood-modal.modal5 .img {
        width: 176px;
        height: 176px;
        float: right;
        margin-top: 12px; }
    .header section.img-board .mood-modal.modal6 {
      right: 5%;
      top: 0%;
      border-radius: 10px 110px 10px 10px; }
      .header section.img-board .mood-modal.modal6 .heading {
        float: left;
        width: 55%; }
      .header section.img-board .mood-modal.modal6 .message {
        clear: both;
        padding: 1em 0; }
      .header section.img-board .mood-modal.modal6 .img {
        width: 204px;
        height: 204px;
        float: right; }
    .header section.img-board .mood-modal.modal7 {
      right: 8%;
      bottom: 10%;
      border-radius: 10px 10px 120px 10px; }
      .header section.img-board .mood-modal.modal7 .message {
        margin: 0 0 2em; }
      .header section.img-board .mood-modal.modal7 .img {
        width: 258px;
        height: 258px;
        float: right; }
    .header section.img-board .mood-modal.modal8 {
      left: 25%;
      top: 15%;
      border-radius: 70px 10px 10px 10px; }
      .header section.img-board .mood-modal.modal8 .heading {
        float: right;
        width: 65%;
        font-size: 2.125rem; }
      .header section.img-board .mood-modal.modal8 .message {
        clear: both;
        padding: 1em 0; }
      .header section.img-board .mood-modal.modal8 .plus, .header section.img-board .mood-modal.modal8 .people-icon {
        width: 142px;
        height: 142px;
        float: left; }
    .header section.img-board .mood-modal.modal9 {
      right: 20%;
      top: 30%;
      border-radius: 10px 60px 10px 10px; }
      .header section.img-board .mood-modal.modal9 .heading {
        float: left;
        width: 70%; }
      .header section.img-board .mood-modal.modal9 .message {
        clear: both;
        padding: 1em 0; }
      .header section.img-board .mood-modal.modal9 .plus, .header section.img-board .mood-modal.modal9 .people-icon {
        width: 103px;
        height: 103px;
        float: right; }
    .header section.img-board .mood-modal.modal10 {
      right: 38%;
      bottom: 10%;
      border-radius: 10px 10px 60px 10px; }
      .header section.img-board .mood-modal.modal10 .plus, .header section.img-board .mood-modal.modal10 .people-icon {
        height: 93px;
        width: 93px;
        float: right;
        margin-top: 90px; }
      .header section.img-board .mood-modal.modal10 .message {
        margin: 0 0 2em; }
      .header section.img-board .mood-modal.modal10 .control-block .control-row {
        padding-bottom: .5em; }
    .header section.img-board .mood-modal.active {
      background: rgba(43, 43, 42, 0.9);
      width: 500px;
      cursor: initial;
      z-index: 10; }
      .header section.img-board .mood-modal.active:hover img {
        box-shadow: none; }
      .header section.img-board .mood-modal.active .blockquote {
        display: block; }
      .header section.img-board .mood-modal.active .button {
        display: block; }
      .header section.img-board .mood-modal.active .plus {
        display: none; }
      .header section.img-board .mood-modal.active .people-icon {
        display: block;
        border: 4px solid #ffffff;
        border-radius: 90%;
        padding: 4px; }
      .header section.img-board .mood-modal.active .video-block {
        margin-bottom: 1em;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%; }
        .header section.img-board .mood-modal.active .video-block .play {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: #ffa400;
          border-radius: 50%;
          width: 70px;
          height: 70px;
          transition: background-color .2s ease; }
          .header section.img-board .mood-modal.active .video-block .play:hover {
            background-color: #db423c; }
          .header section.img-board .mood-modal.active .video-block .play i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2rem;
            color: #ffffff; }
      .header section.img-board .mood-modal.active h3, .header section.img-board .mood-modal.active p, .header section.img-board .mood-modal.active div, .header section.img-board .mood-modal.active .control-block, .header section.img-board .mood-modal.active .control-row {
        display: block; }
      .header section.img-board .mood-modal.active .button-row {
        display: flex; }
      .header section.img-board .mood-modal.active .button {
        display: inline-block;
        margin: 0 auto; }
    .header section.img-board .mood-modal .control-row {
      text-align: center;
      padding: 1.5em 0;
      font-size: 1.5em; }
      .header section.img-board .mood-modal .control-row a {
        cursor: pointer; }
      .header section.img-board .mood-modal .control-row .prev, .header section.img-board .mood-modal .control-row .next {
        font-weight: 700;
        margin: 0 1.5em; }
      .header section.img-board .mood-modal .control-row .close {
        color: #575757; }
    .header section.img-board .mood-modal h3 {
      font-family: "Merriweather", serif;
      color: #ffffff;
      font-size: 2.25rem; }
    .header section.img-board .mood-modal p {
      color: #ffffff;
      font-size: 1.25rem; }
    .header section.img-board .mood-modal a {
      color: #ffffff; }
    .header section.img-board .mood-modal h3, .header section.img-board .mood-modal p, .header section.img-board .mood-modal div {
      display: none; }
    @media screen and (max-width: 1200px) {
      .header section.img-board .mood-modal.modal3 {
        display: none; }
      .header section.img-board .mood-modal.modal10 {
        display: none !important; } }
    @media (max-width: 1023px) {
      .header section.img-board .mood-modal.modal3 {
        display: none; }
      .header section.img-board .mood-modal.active {
        border-radius: 10px;
        background: rgba(10, 10, 10, 0.95);
        left: 0;
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        flex-direction: column;
        top: 65px !important;
        left: 5% !important;
        width: 80%;
        height: 80%;
        transition: none;
        padding: 5%;
        align-items: center;
        z-index: 100; }
        .header section.img-board .mood-modal.active .img {
          height: 30vw !important;
          width: 30vw !important;
          margin-top: 12px;
          float: none;
          order: -1; }
        .header section.img-board .mood-modal.active .video-block {
          margin-top: 3em; }
        .header section.img-board .mood-modal.active .heading {
          float: none;
          width: 100%;
          margin: .75em 0; }
        .header section.img-board .mood-modal.active .control-block {
          width: 100%;
          flex-grow: 2;
          display: flex;
          flex-direction: column;
          justify-content: space-between; } }
    @media screen and (max-width: 1400px) {
      .header section.img-board .mood-modal.modal1 {
        left: 1%; }
      .header section.img-board .mood-modal.modal2 {
        left: 10%; }
      .header section.img-board .mood-modal.modal3 {
        left: 37%; }
      .header section.img-board .mood-modal.modal4 {
        left: 35%; }
      .header section.img-board .mood-modal.modal5 {
        right: 22%; }
      .header section.img-board .mood-modal.modal6 {
        right: 1%; }
      .header section.img-board .mood-modal.modal7 {
        right: 2%; }
      .header section.img-board .mood-modal.modal8 {
        left: 24%; }
      .header section.img-board .mood-modal.modal9 {
        right: 10%; }
      .header section.img-board .mood-modal.modal10 {
        right: 30%; } }
    @media screen and (max-width: 1200px) {
      .header section.img-board .mood-modal.modal1 {
        left: 1%; }
      .header section.img-board .mood-modal.modal2 {
        left: 10%; }
      .header section.img-board .mood-modal.modal3 {
        left: 37%; }
      .header section.img-board .mood-modal.modal4 {
        left: 40%; }
      .header section.img-board .mood-modal.modal5 {
        right: 28%; }
      .header section.img-board .mood-modal.modal6 {
        right: 1%; }
      .header section.img-board .mood-modal.modal7 {
        right: 2%; }
      .header section.img-board .mood-modal.modal8 {
        left: 32%; }
      .header section.img-board .mood-modal.modal9 {
        right: 15%; } }
    @media screen and (max-width: 900px) {
      .header section.img-board .mood-modal.modal1 {
        top: 2.5%;
        left: 2.5%; }
      .header section.img-board .mood-modal.modal2 {
        bottom: 2.5%;
        left: 2.5%; }
      .header section.img-board .mood-modal.modal4 {
        display: none; }
      .header section.img-board .mood-modal.modal6 {
        right: 2.5%;
        top: 10%; }
      .header section.img-board .mood-modal.modal7 {
        bottom: 1%;
        right: 7%; }
      .header section.img-board .mood-modal.modal8 {
        top: 35%;
        left: 25%; }
      .header section.img-board .mood-modal.modal9 {
        display: none; } }
    @media (max-width: 767px) {
      .header section.img-board .mood-modal img {
        transition: none !important; }
      .header section.img-board .mood-modal h3 {
        font-size: 1.75em; }
      .header section.img-board .mood-modal p {
        font-size: 1em; }
      .header section.img-board .mood-modal.active {
        flex-wrap: nowrap;
        justify-content: space-between !important; }
        .header section.img-board .mood-modal.active img, .header section.img-board .mood-modal.active h3, .header section.img-board .mood-modal.active p, .header section.img-board .mood-modal.active .control-block {
          margin: 0 !important;
          padding: 0 !important; }
        .header section.img-board .mood-modal.active .control-block {
          flex-grow: 0; }
      .header section.img-board .mood-modal .button {
        width: 95%;
        text-align: center; }
      .header section.img-board .mood-modal.modal1 {
        top: 15%;
        left: 2.5%; }
        .header section.img-board .mood-modal.modal1 .img {
          width: 23vw;
          height: 23vw; }
      .header section.img-board .mood-modal.modal2 {
        display: none; }
      .header section.img-board .mood-modal.modal3 {
        display: flex;
        bottom: 8%;
        left: 5%;
        top: auto; }
        .header section.img-board .mood-modal.modal3 .img {
          width: 26vw;
          height: 26vw; }
      .header section.img-board .mood-modal.modal5 {
        bottom: 20%;
        right: 15%; }
        .header section.img-board .mood-modal.modal5 .img {
          width: 18vw;
          height: 18vw; }
      .header section.img-board .mood-modal.modal6 {
        top: 20%;
        right: 7.5%; }
        .header section.img-board .mood-modal.modal6 .img {
          width: 30vw;
          height: 30vw; }
      .header section.img-board .mood-modal.modal7 {
        display: none; }
      .header section.img-board .mood-modal.modal8 {
        top: 40%;
        left: 20%; }
        .header section.img-board .mood-modal.modal8 .plus, .header section.img-board .mood-modal.modal8 .people-icon {
          width: 17vw;
          height: 17vw; } }

@media (max-width: 1023px) {
  .header .header-message.w-board h1 {
    font-size: 2.75rem;
    margin: .83em .5em 0; }
  .header .header-message.gap h1 {
    margin: .83em 2rem; }
  .header .header-message h1 {
    font-size: 3.5rem;
    margin: .83em 1em 0; }
  .header .header-message p {
    font-size: 1.125rem;
    width: 70%;
    margin: 1em auto 0; }
  .header .mobile-menu-bar {
    display: flex;
    justify-content: space-between; }
  .header .menu {
    padding: 0; }
    .header .menu-right, .header .menu-left {
      display: none; }
    .header .menu.open {
      flex-direction: column;
      background-color: #1f3254;
      justify-content: flex-start;
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 11; }
      .header .menu.open .menu-right, .header .menu.open .menu-left {
        display: block;
        flex-basis: auto !important; }
        .header .menu.open .menu-right .menu-left-item, .header .menu.open .menu-left .menu-left-item {
          padding: .5em 1em;
          margin: 0; }
          .header .menu.open .menu-right .menu-left-item:nth-of-type(odd), .header .menu.open .menu-left .menu-left-item:nth-of-type(odd) {
            background-color: #172847; }
        .header .menu.open .menu-right .menu-right-item, .header .menu.open .menu-left .menu-right-item {
          padding: .5em 0;
          margin: 0 1rem; }
          .header .menu.open .menu-right .menu-right-item.social-block a, .header .menu.open .menu-left .menu-right-item.social-block a {
            margin: 0 10px; }
            .header .menu.open .menu-right .menu-right-item.social-block a i, .header .menu.open .menu-left .menu-right-item.social-block a i {
              color: #1f3254; }
  .header .logo {
    display: none; } }

@media (max-width: 767px) {
  .header .header-message.w-board h1 {
    font-size: 1.25rem;
    margin: .83em .5em 0; }
  .header .header-message.gap {
    margin-top: 0; }
    .header .header-message.gap h1 {
      margin: 0; }
  .header .header-message h1 {
    font-size: 1.25rem;
    margin: .83em 2em 0; }
  .header .header-message p {
    font-size: 1rem;
    width: 90%;
    margin: 1em auto 0; }
  .header section.img-board {
    height: 125vw; }
    .header section.img-board .btn-ctr .button.bl {
      width: 90%;
      text-align: center; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  margin: 1.75em 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible; }

.hamburger:hover {
  opacity: 0.7; }

.hamburger.is-active:hover {
  opacity: 0.7; }

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff; }

.hamburger.is-active.hamburger--slider .hamburger-inner::after {
  top: 20px; }

.hamburger.is-active.hamburger--slider .hamburger-inner::before {
  top: 10px; }

.hamburger-box {
  width: 20px;
  height: 15px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 100px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }

.hamburger-inner::before {
  top: -8px; }

.hamburger-inner::after {
  bottom: -8px; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important; }

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }

.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }

.hamburger--slider .hamburger-inner::after {
  top: 16px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg); }

footer .footer-wrap .footer-top {
  background-color: #1d262f; }
  footer .footer-wrap .footer-top .footer-inner {
    width: 90%;
    margin: 0 auto;
    padding: 4em 0; }
    footer .footer-wrap .footer-top .footer-inner .feature-links-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block {
        padding-bottom: 2rem; }
        footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block h3 {
          font-family: "Libre Franklin", sans-serif;
          font-size: 1.875rem;
          color: #ffffff;
          font-weight: 900;
          margin: 0 0 0.75rem; }
          footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block h3 a {
            font-family: "Libre Franklin", sans-serif;
            font-size: 1.875rem;
            color: #ffffff;
            text-decoration: none;
            font-weight: 900;
            transition: color 0.2s ease; }
            footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block h3 a:hover {
              color: #db423c; }
        footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block .message {
          font-family: "Merriweather", serif;
          color: #7f90a2;
          width: 75%;
          font-style: italic;
          font-size: 0.75rem; }
    footer .footer-wrap .footer-top .footer-inner .network-links-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block {
        margin-left: 3em; }
        footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block h3 {
          font-family: "Libre Franklin", sans-serif;
          font-size: 1rem;
          color: #ffa400;
          font-weight: 600;
          margin: 0; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block h3 a {
            font-family: "Libre Franklin", sans-serif;
            font-size: 1rem;
            color: #ffa400;
            text-decoration: none;
            font-weight: 600;
            transition: color 0.2s ease;
            display: flex;
            align-items: center; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block h3 a i {
              color: #ffffff;
              padding-left: 0.5em;
              font-size: 0.75em; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block h3 a[href]:hover {
            color: #ffffff; }
        footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .message {
          font-family: "Merriweather", serif;
          color: #ffffff;
          width: 75%;
          font-style: italic;
          font-size: 0.75rem; }
        footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links {
          margin-top: 1em; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a i {
            transition: all 0.3s ease; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a:hover svg.yt, footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a:hover svg.fb, footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a:hover svg.tw, footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a:hover svg.ig {
            background-color: transparent; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg {
            color: #ffffff;
            border-radius: 12px;
            margin-right: 0.5rem;
            height: 34px;
            width: 34px;
            position: relative;
            padding: 0.5rem; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg path {
              fill: white !important; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg:before {
              position: absolute;
              top: 50%;
              right: 50%; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.fb {
              background-color: #3b5998;
              border: 2px solid #3b5998; }
              footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.fb:before {
                margin: -9px -8px 0 0; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.tw {
              background-color: #000000;
              border: 2px solid #000000; }
              footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.tw:before {
                margin: -9px -8px 0 0; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.yt {
              background-color: #ff0000;
              border: 2px solid #ff0000; }
              footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.yt:before {
                margin: -9.25px -9.5px 0 0; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.ig {
              background-color: #26506c;
              border: 2px solid #26506c; }
              footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a svg.ig:before {
                margin: -9px -8px 0 0; }
    footer .footer-wrap .footer-top .footer-inner .form-col h3 {
      font-family: "Libre Franklin", sans-serif;
      font-size: 1.875rem;
      color: #ffffff;
      font-weight: 900;
      margin: 0; }
      footer .footer-wrap .footer-top .footer-inner .form-col h3 a {
        font-family: "Libre Franklin", sans-serif;
        font-size: 1.875rem;
        color: #ffffff;
        text-decoration: none;
        font-weight: 900; }
    footer .footer-wrap .footer-top .footer-inner .form-col .message {
      font-family: "Merriweather", serif;
      color: #ffffff;
      width: 75%;
      font-style: italic;
      font-size: 0.75rem; }
    footer .footer-wrap .footer-top .footer-inner .form-col input,
    footer .footer-wrap .footer-top .footer-inner .form-col button {
      display: block;
      outline: 0;
      border: 0;
      margin: 2rem 0; }
    footer .footer-wrap .footer-top .footer-inner .form-col input {
      width: 80%;
      font-size: 1.125rem;
      padding: 0.75em; }
      footer .footer-wrap .footer-top .footer-inner .form-col input::placeholder {
        font-size: 1.125rem;
        color: #7f7f7f; }
    @media (max-width: 1023px) {
      footer .footer-wrap .footer-top .footer-inner .feature-links-col {
        flex-basis: 50%; }
      footer .footer-wrap .footer-top .footer-inner .network-links-col {
        flex-basis: 50%; }
      footer .footer-wrap .footer-top .footer-inner .form-col {
        flex-basis: 75%;
        margin-top: 4em; } }
    @media (max-width: 767px) {
      footer .footer-wrap .footer-top .footer-inner .feature-links-col {
        flex-basis: 100%; }
        footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block h3 {
          font-size: 1.125rem; }
          footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block h3 a {
            font-size: 1.125rem; }
        footer .footer-wrap .footer-top .footer-inner .feature-links-col .footer-block .message {
          width: 100%; }
      footer .footer-wrap .footer-top .footer-inner .network-links-col {
        flex-basis: 100%;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap; }
        footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block {
          margin: 1rem 0; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block h3 {
            font-size: 0.8rem; }
            footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block h3 a {
              font-size: 0.8rem; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block:nth-child(odd) {
            flex-basis: 35%; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block:nth-child(even) {
            flex-basis: 65%; }
          footer .footer-wrap .footer-top .footer-inner .network-links-col .footer-block .footer-social-links a i {
            margin-right: 0.25rem; }
      footer .footer-wrap .footer-top .footer-inner .form-col {
        flex-basis: 100%;
        margin-top: 4em; }
        footer .footer-wrap .footer-top .footer-inner .form-col button {
          width: 100%; }
        footer .footer-wrap .footer-top .footer-inner .form-col input {
          width: 90%;
          padding: 0.75rem 5%; } }

footer .footer-wrap .footer-bottom .footer-inner {
  width: 90%;
  margin: 1.5rem auto;
  align-items: center; }
  footer .footer-wrap .footer-bottom .footer-inner .fb-left {
    text-align: right;
    color: #7f7f7f;
    font-size: 12px;
    font-family: "Libre Franklin", sans-serif;
    line-height: 24px; }
    footer .footer-wrap .footer-bottom .footer-inner .fb-left .footer-links {
      padding-top: 2em; }
    footer .footer-wrap .footer-bottom .footer-inner .fb-left .footer-links > a {
      font-size: 12px;
      font-family: "Libre Franklin", sans-serif;
      line-height: 24px;
      text-decoration: none;
      padding-left: 2em;
      transition: color 0.2s ease; }
      footer .footer-wrap .footer-bottom .footer-inner .fb-left .footer-links > a:hover {
        color: #c6c6c6; }
  @media (max-width: 1023px) {
    footer .footer-wrap .footer-bottom .footer-inner .fb-left {
      flex-basis: 100%;
      margin-top: 2rem;
      text-align: center; }
    footer .footer-wrap .footer-bottom .footer-inner .fb-right {
      flex-basis: 100%;
      display: flex;
      justify-content: center; }
      footer .footer-wrap .footer-bottom .footer-inner .fb-right img {
        width: 80%; } }
  @media (max-width: 767px) {
    footer .footer-wrap .footer-bottom .footer-inner .fb-left {
      flex-basis: 100%;
      margin-top: 2rem;
      text-align: center;
      font-size: 10px; }
    footer .footer-wrap .footer-bottom .footer-inner .fb-right {
      flex-basis: 100%; }
      footer .footer-wrap .footer-bottom .footer-inner .fb-right img {
        width: 100%; } }

@media (max-width: 767px) {
  .cc-revoke.cc-bottom {
    display: none; } }

.sort-menu select {
  width: 11vw; }
  .sort-menu select:first-of-type {
    margin-right: 1rem; }

@media (max-width: 767px) {
  .sort-menu {
    flex-basis: 100%; }
    .sort-menu select {
      width: 95%;
      padding: 1rem 0 1rem 5%;
      margin-bottom: 1.5rem; }
      .sort-menu select:first-of-type {
        margin-right: 0; } }

section.system-selector .selection-wrapper {
  width: 85.7vw;
  margin: 5rem auto;
  justify-content: space-between; }
  section.system-selector .selection-wrapper .content-block {
    width: 23.4vw;
    border: 1px solid #c6c6c6;
    padding: 1.7vw;
    position: relative;
    cursor: pointer;
    transition: all .2s ease; }
    section.system-selector .selection-wrapper .content-block .header {
      font-family: "Libre Franklin", sans-serif;
      font-size: 2.125rem;
      font-weight: 900;
      transition: all .2s ease; }
    section.system-selector .selection-wrapper .content-block .message {
      font-family: "Merriweather", serif;
      font-size: .75rem;
      font-style: italic;
      transition: all .2s ease; }
    section.system-selector .selection-wrapper .content-block:hover, section.system-selector .selection-wrapper .content-block.active {
      background-color: #2b2b2a;
      border-color: #2b2b2a; }
      section.system-selector .selection-wrapper .content-block:hover .header, section.system-selector .selection-wrapper .content-block:hover .message, section.system-selector .selection-wrapper .content-block.active .header, section.system-selector .selection-wrapper .content-block.active .message {
        color: #ffffff; }
    @media (min-width: 1024px) {
      section.system-selector .selection-wrapper .content-block.active::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -25px;
        width: 0;
        height: 0;
        border-top: solid 25px #2b2b2a;
        border-left: solid 25px transparent;
        border-right: solid 25px transparent; } }
  @media (max-width: 1023px) {
    section.system-selector .selection-wrapper {
      margin: 6vw auto; }
      section.system-selector .selection-wrapper .content-block {
        width: 100%;
        margin: 1vw 0; }
        section.system-selector .selection-wrapper .content-block .header {
          font-size: 1.5rem; } }

section.guides {
  display: none;
  margin: 0 auto;
  width: 85.7vw; }
  section.guides .button {
    background-color: #eef0f4;
    border: 1px solid #d8d8d8;
    padding: 1rem 3rem;
    font-size: .75rem;
    font-family: "Libre Franklin", sans-serif;
    font-weight: 600;
    color: #575757; }
    section.guides .button:hover {
      color: #ffffff;
      background-color: #595a58; }
  section.guides .purchase-link {
    font-family: "Merriweather", serif;
    font-style: italic;
    color: #425d8c;
    text-decoration: none;
    font-size: 0.6875rem; }
    section.guides .purchase-link:hover {
      color: #c6c6c6; }
  section.guides.active {
    display: block; }
  section.guides .guides-pageheader {
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 2.5vw;
    margin-bottom: 2.5vw; }
    section.guides .guides-pageheader .cover-wrap {
      flex-basis: 25vw; }
      section.guides .guides-pageheader .cover-wrap img {
        width: 100%;
        height: auto; }
    section.guides .guides-pageheader .guides-content-wrap {
      flex-basis: 56vw; }
      section.guides .guides-pageheader .guides-content-wrap .guides-heading h2 {
        display: inline-block;
        margin: 0 0 1.5rem; }
      section.guides .guides-pageheader .guides-content-wrap .guides-heading .sub {
        display: inline-block;
        font-family: "Merriweather", serif;
        font-style: italic;
        font-size: 0.6875rem;
        color: #575757; }
    section.guides .guides-pageheader .guides-description {
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-left: -4%; }
      section.guides .guides-pageheader .guides-description .desc {
        font-family: "Libre Franklin", sans-serif;
        font-size: 0.8125rem;
        line-height: 1.54em;
        flex-basis: 45%;
        padding-left: 4%; }
    section.guides .guides-pageheader .controls select {
      min-width: 9.6vh;
      margin: 2rem 1rem 0 0; }
      section.guides .guides-pageheader .controls select:nth-of-type(2) {
        min-width: 25vh; }
  section.guides .more-reading .heading-row {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    section.guides .more-reading .heading-row h2 {
      display: inline-block; }
  section.guides .more-reading .reading-grid-wrapper {
    width: 103%;
    margin-left: -1.5%; }
  section.guides .more-reading .reading-grid .item {
    display: none;
    width: 14.167%;
    flex-direction: column;
    align-items: center;
    margin: 0 1.25% 6em; }
    section.guides .more-reading .reading-grid .item.active {
      display: flex; }
    section.guides .more-reading .reading-grid .item .button {
      width: 100%;
      text-align: center;
      padding: 1rem 0; }
    section.guides .more-reading .reading-grid .item select {
      padding: 1rem 0 1rem 10%;
      width: 90%;
      margin-top: 1rem; }
    section.guides .more-reading .reading-grid .item img {
      display: none;
      max-height: 320px;
      max-width: 100%; }
      section.guides .more-reading .reading-grid .item img.active {
        display: block; }
  @media (max-width: 1023px) {
    section.guides .guides-pageheader .guides-content-wrap .guides-heading h2 {
      display: block;
      margin: 0; }
    section.guides .guides-pageheader .guides-content-wrap .guides-heading .sub {
      display: block;
      margin: 0 0 1.5rem; }
    section.guides .guides-pageheader .guides-content-wrap .guides-description {
      flex-wrap: wrap; }
      section.guides .guides-pageheader .guides-content-wrap .guides-description .desc {
        flex-basis: 100%; }
        section.guides .guides-pageheader .guides-content-wrap .guides-description .desc:not(:first-of-type) {
          margin-top: 1rem; }
    section.guides .guides-pageheader .controls select {
      width: 95%;
      padding: 1rem 0 1rem 5%;
      margin: 2rem 1rem 0 0; }
    section.guides .guides-pageheader .controls .button {
      margin: 2rem 1rem 2rem 0;
      width: 50%;
      text-align: center;
      padding: 1rem 0; }
    section.guides .more-reading .heading-row h2 {
      font-size: 2.5rem; }
    section.guides .more-reading .reading-grid .item.active {
      display: flex; }
    section.guides .more-reading .reading-grid .item {
      width: 22.5%;
      flex-direction: column;
      align-items: center;
      margin: 0 1.25% 6rem; }
      section.guides .more-reading .reading-grid .item .button {
        width: 100%;
        text-align: center;
        padding: 1rem 0; }
      section.guides .more-reading .reading-grid .item select {
        padding: 1rem 0 1rem 10%;
        width: 90%;
        margin-top: 1rem; } }
  @media (max-width: 767px) {
    section.guides .guides-pageheader .cover-wrap {
      flex-basis: 100%;
      margin-bottom: 6vw; }
    section.guides .guides-pageheader .guides-content-wrap {
      flex-basis: 100%; }
    section.guides .more-reading .heading-row {
      flex-wrap: wrap; }
      section.guides .more-reading .heading-row h2 {
        flex-basis: 100%;
        display: block;
        font-size: 1.5rem; }
    section.guides .more-reading .reading-grid .item {
      width: 48%;
      flex-direction: column;
      align-items: center;
      margin: 0 1% 6rem; }
      section.guides .more-reading .reading-grid .item .button {
        width: 100%;
        text-align: center;
        padding: 1rem 0; }
      section.guides .more-reading .reading-grid .item select {
        padding: 1rem 0 1rem 10%;
        width: 90%;
        margin-top: 1rem; } }

section.example-systems {
  display: none;
  margin: 0 auto;
  width: 85.7vw; }
  section.example-systems.active {
    display: block; }
  section.example-systems .menu-row {
    justify-content: space-between; }
    section.example-systems .menu-row .row-left .button {
      margin: 0;
      padding: .5rem 2rem; }
    section.example-systems .menu-row .row-right .sort-menu {
      width: 100%; }
    section.example-systems .menu-row .row-right select {
      padding: .75rem 0 .75rem 1rem;
      width: 11vw; }
      section.example-systems .menu-row .row-right select:first-of-type {
        margin-right: 2rem; }
  section.example-systems .systems-grid-wrapper {
    width: 103%;
    margin: 0 -1.5%; }
  section.example-systems .systems-grid {
    margin: 0 0 5rem; }
    section.example-systems .systems-grid > a {
      flex-basis: 22.5%;
      outline: 1px solid #d8d8d8;
      background-color: #eef0f4;
      margin: 2.8vw 1.25% 0;
      text-decoration: none;
      transition: all .2s ease; }
      section.example-systems .systems-grid > a.item {
        display: none; }
        section.example-systems .systems-grid > a.item.active {
          display: flex; }
      section.example-systems .systems-grid > a .system .img-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 6.5%;
        height: 19.4vw;
        border-bottom: 1px solid #d8d8d8;
        background-color: #ffffff;
        transition: border-bottom .2s ease; }
        section.example-systems .systems-grid > a .system .img-wrapper img {
          max-height: 100%;
          max-width: 100%; }
        section.example-systems .systems-grid > a .system .img-wrapper i {
          font-size: 4rem;
          color: #c6c6c6; }
      section.example-systems .systems-grid > a .system .system-content {
        padding: 1rem; }
        section.example-systems .systems-grid > a .system .system-content .title {
          font-size: 1.125rem;
          margin-bottom: .75rem;
          color: #373737;
          font-weight: 400;
          transition: color .2s ease; }
        section.example-systems .systems-grid > a .system .system-content .desc {
          font-family: "Merriweather", serif;
          font-size: 0.6875rem;
          color: #373737;
          line-height: 1.63em;
          transition: color .2s ease; }
      section.example-systems .systems-grid > a:hover {
        outline: 1px solid #2b2b2a;
        background-color: #494949; }
        section.example-systems .systems-grid > a:hover .system .img-wrapper {
          border-bottom: 1px solid #2b2b2a; }
        section.example-systems .systems-grid > a:hover .system .system-content .title {
          color: #ffffff; }
        section.example-systems .systems-grid > a:hover .system .system-content .desc {
          color: #ffffff; }
  @media (max-width: 1023px) {
    section.example-systems .menu-row .row-right {
      flex-basis: 64%;
      justify-content: space-between;
      display: flex; }
      section.example-systems .menu-row .row-right select {
        width: 45%;
        margin: 0; }
        section.example-systems .menu-row .row-right select:first-of-type {
          margin: 0; }
    section.example-systems .systems-grid-wrapper {
      width: 103%;
      margin: 0 -1.5%; }
    section.example-systems .systems-grid {
      margin: 3rem 0 5rem; }
      section.example-systems .systems-grid > a {
        flex-basis: 48%;
        margin: 0 1% 10vw; }
        section.example-systems .systems-grid > a .system .img-wrapper {
          height: 40vw; } }
  @media (max-width: 767px) {
    section.example-systems .menu-row .row-left {
      flex-basis: 100%; }
      section.example-systems .menu-row .row-left .button {
        margin: 1rem 0 1.5rem 0;
        width: 100%;
        text-align: center;
        padding: .5rem 0; }
    section.example-systems .menu-row .row-right {
      flex-basis: 100%; }
      section.example-systems .menu-row .row-right select {
        padding: .75rem 0 .75rem 5%;
        width: 95%; }
        section.example-systems .menu-row .row-right select:first-of-type {
          margin: 0 0 1.5rem; }
    section.example-systems .systems-grid {
      margin: 3rem 0 5rem; }
      section.example-systems .systems-grid > a {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 10vw; }
        section.example-systems .systems-grid > a .system .img-wrapper {
          height: 80vw; } }

section.courses {
  display: none;
  margin: 0 auto;
  width: 85.7vw; }
  section.courses.active {
    display: block; }
  section.courses .course-wrapper {
    flex-wrap: nowrap;
    margin-bottom: 6vw; }
    section.courses .course-wrapper .course {
      flex: 1 0 50%; }
      section.courses .course-wrapper .course h3 {
        font-family: "Merriweather", serif;
        font-size: 1.875rem; }
      section.courses .course-wrapper .course .video-wrapper {
        position: relative; }
        section.courses .course-wrapper .course .video-wrapper img {
          width: 100%;
          height: auto; }
        section.courses .course-wrapper .course .video-wrapper a svg {
          position: absolute;
          width: 50px;
          top: 50%;
          margin-top: -1.775vw;
          left: 50%;
          margin-left: -2.8vw;
          background: rgba(219, 66, 60, 0.5);
          padding: .925vw 2.0625vw;
          color: #ffffff;
          transition: background-color .2s ease; }
        section.courses .course-wrapper .course .video-wrapper a:hover svg {
          background-color: rgba(186, 33, 26, 0.5); }
      section.courses .course-wrapper .course .heading {
        font-weight: 400;
        font-size: 1.375rem;
        margin: 1rem 0; }
      section.courses .course-wrapper .course .description {
        font-family: "Merriweather", serif;
        font-size: .6875rem;
        line-height: 1.81em; }
      section.courses .course-wrapper .course .button {
        margin-left: 0; }
      section.courses .course-wrapper .course:not(:last-of-type) {
        margin-right: 3%; }
  @media (max-width: 1023px) {
    section.courses .course-wrapper {
      display: block; }
      section.courses .course-wrapper .course .video-wrapper img {
        width: 100%;
        height: auto; }
      section.courses .course-wrapper .course .video-wrapper a svg {
        font-size: 3vw;
        margin-top: -3.5vw;
        margin-left: -2.8vw;
        padding: 2.0625vw;
        border-radius: 100%; }
      section.courses .course-wrapper .course:not(:last-of-type) {
        margin-right: 0; } }
  @media (max-width: 767px) {
    section.courses {
      width: 100%; }
      section.courses .course-wrapper .course h3, section.courses .course-wrapper .course .heading, section.courses .course-wrapper .course .description, section.courses .course-wrapper .course .button {
        width: 85.7%;
        margin-left: auto;
        margin-right: auto; }
      section.courses .course-wrapper .course .video-wrapper img {
        width: 100%;
        height: auto; }
      section.courses .course-wrapper .course .video-wrapper a svg {
        margin-top: -12vw;
        margin-left: -12vw;
        padding: 4vw; }
      section.courses .course-wrapper .course .button {
        padding: 1em 0;
        text-align: center;
        display: block; } }

section#system-page-content {
  font-family: "Merriweather", serif;
  width: 70%;
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 0; }
  section#system-page-content p, section#system-page-content a, section#system-page-content ul, section#system-page-content ol, section#system-page-content span {
    font-family: "Merriweather", serif;
    line-height: 2.125rem; }
    @media (max-width: 767px) {
      section#system-page-content p, section#system-page-content a, section#system-page-content ul, section#system-page-content ol, section#system-page-content span {
        line-height: 1.625rem; } }
  section#system-page-content a {
    color: #db423c;
    text-decoration: none;
    font-style: initial; }
    section#system-page-content a:hover {
      color: #ba211a; }
  section#system-page-content .breadcrumbs {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #2b2b2a; }
    section#system-page-content .breadcrumbs span {
      font-weight: bold; }
  section#system-page-content .system-image {
    display: flex;
    justify-content: center; }
  section#system-page-content .return {
    margin-top: 5rem;
    text-align: center; }
    section#system-page-content .return:before {
      content: "<"; }
  @media (max-width: 767px) {
    section#system-page-content {
      width: 85%; } }

.cc-message, .cc-link {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 400;
  font-size: .8125rem; }

.cc-btn {
  font-family: "Libre Franklin", sans-serif; }

.cc-revoke.cc-bottom {
  font-family: "Libre Franklin", sans-serif;
  font-size: .8125rem !important;
  right: 50px !important;
  bottom: 0 !important;
  left: auto !important; }

.cc-type-info {
  display: none !important; }

.cc-animate.cc-revoke.cc-bottom,
.cc-animate.cc-revoke.cc-bottom.cc-active {
  transform: none !important;
  transition: none !important;
  bottom: 0 !important; }
