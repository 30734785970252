section.courses {
    display: none;
    margin: 0 auto;
    width: 85.7vw;

    &.active {
        display: block;
    }
    
    .course-wrapper {
        flex-wrap: nowrap;
        margin-bottom: 6vw;
        .course {
            flex: 1 0 50%;
            h3 {
                font-family: $merriweather;
                font-size: 1.875rem;
            }
            .video-wrapper {
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                }
                
                a {
                    svg {
                        position: absolute;
                        width: 50px;
                        top: 50%;
                        margin-top: -1.775vw;
                        left: 50%;
                        margin-left: -2.8vw;
                        background: $transRed;
                        padding: .925vw 2.0625vw;
                        color: $white;
                        transition: background-color .2s ease;
                    }

                    &:hover {
                        svg {
                            background-color: $transDark-red;
                        }
                    }
                }
                
            }

            .heading {
                font-weight: 400;
                font-size: 1.375rem;
                margin: 1rem 0;
            }

            .description {
                font-family: $merriweather;
                font-size: .6875rem;
                line-height: 1.81em;
            }

            .button{
                margin-left: 0;
            }

            &:not(:last-of-type) {
                margin-right: 3%;
            }
        }
    }

    @include breakpoint(tablet, max) {
        .course-wrapper {
            display: block;
            .course {
                .video-wrapper {
                    img {
                        width: 100%;
                        height: auto;
                    }
                    
                    a {
                        svg {
                            font-size: 3vw;
                            margin-top: -3.5vw;
                            margin-left: -2.8vw;
                            padding: 2.0625vw;
                            border-radius: 100%;
                        }
                    }
                }
    
                &:not(:last-of-type) {
                    margin-right: 0;
                }
            }
        }
    }

    @include breakpoint(mobile, max) {
        width: 100%;
        .course-wrapper {
            .course {
                h3, .heading, .description, .button {
                    width: 85.7%;
                    margin-left: auto;
                    margin-right: auto;
                }
                .video-wrapper {
    
                    img {
                        width: 100%;
                        height: auto;
                    }
                    
                    a {
                        svg {
                            margin-top: -12vw;
                            margin-left: -12vw;
                            padding: 4vw;
                        }
                    }
                    
                }
    
                .button{
                    padding: 1em 0;
                    text-align: center;
                    display: block;
                }
            }
        }
    }
}