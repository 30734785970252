section.news-block {
	background-color: $navy-blue;
	&.right {
		.news-wrap {
			padding: 4em 0 2em 0;
			margin-left: 6em;

			.news-thumb {
				.file {
					right: 15%;
				}
			}
		}
	}

	&.left {
		.news-wrap {
			padding: 4em 0 2em 0;
			margin-right: 6em;

			.news-header {
				display: flex;
				justify-content: flex-end;
				width: 100%;
			}

			.news-thumb {
				.file {
					left: 15%;
				}
			}

			.news-details {
				div {
					margin-left: 2em;
				}
			}
		}
	}
	.news-wrap {
		position: relative;
		overflow-x: hidden;

		.news-header {
			position: absolute;
			width: 100%;
			z-index: 10;
			h2 {
				color: $white;
				font-size: 4.25em;
			}
		}
		.news {
			align-items: center;
			margin-top: 9em;

			&.no-header {
				margin-top: 2.5em;
			}
			&-details {
				height: 100%;
				margin-top: 5em;
				.news-message {
					p {
						color: $white;
					}
					width: 66%;
					margin-bottom: 3em;
					font-size: 1.25em;
				}
				.button {
					margin: 0;
				}
			}

			&-thumb {
				display: flex;
				justify-content: flex-end;
				position: relative;
				.thumb {
					width: 100%;
					align-self: center;
					&.right {
						border-radius: 20px 0 0 20px;
					}

					&.left {
						border-radius: 0 20px 20px 0;
					}
				}
				.file {
					position: absolute;
					top: -75px;
					background-color: $black;
					border-radius: 50%;
					width: 150px;
					height: 150px;
					z-index: 10;
					transition: background-color 0.2s ease;
					color: $white;

					svg {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						font-size: 4rem;
						text-align: center;
						color: $white;
					}
				}
			}
		}
	}

	@include breakpoint(1400px, max) {
		&.right,
		&.left {
			.news-wrap {
				padding: 2em 0;
				.news-header {
					position: relative;

					h2 {
						margin: 0.5rem 0 2rem 0.5rem;
						font-size: 3.75rem;
					}
				}
				.news {
					margin-top: 0;
				}
			}
		}
	}

	@include breakpoint(tablet, max) {
		&.right,
		&.left {
			.news-wrap {
				padding: 2em 0;
				margin: 0;

				.news-header {
					position: static;
					display: block;
					h2 {
						font-size: 3.5rem;
						margin: 0 1.75rem;

						br {
							display: none;
						}
					}
				}

				.news {
					margin-top: 2rem;
					&-details {
						margin: 0;
						flex-basis: 100%;
						.news-message {
							margin: 1em auto;
						}
						.btn {
							display: flex;
							justify-content: center;
							margin-left: 0;

							.button {
								width: 40%;
								text-align: center;
							}
						}
					}

					&-thumb {
						.thumb {
							&.right,
							&.left {
								border-radius: 0px;
							}
						}
						flex-basis: 100%;
						order: -1;

						.file {
							width: 70px;
							height: 70px;
							top: -35px;
							left: 50%;
							transform: translateX(-50%);

							i {
								font-size: 2rem;
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(mobile, max) {
		&.right,
		&.left {
			.news-wrap {
				.news-header {
					h2 {
						font-size: 1.5rem;
					}
				}

				.news {
					margin-top: 3rem;

					&.no-header {
						margin-top: 2.5rem;
					}
					&-details {
						.news-message {
							margin: 0 auto;
							width: 90%;
							font-size: 1em;
						}
						.btn {
							.button {
								width: 90%;
								padding: 1em 0;
							}
						}
					}
				}
			}
		}
	}
}
