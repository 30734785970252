section.story-form {
    .form-wrapper {
        width: 56%;
        margin: 0 auto;
        text-align: center;
        h2 {
            font-size: 4.25rem;
        }

        form {
            text-align: left;
            margin: 4em 0;
        }

        .message {
            font-size: 1.25rem;
        }

        @include breakpoint(tablet, max) {
            width: 80%;
            h2 {
                font-size: 2.5rem;
            }
        }

        @include breakpoint(mobile, max) {
            h2 {
                font-size: 1.5rem;
            }
    
            .message {
                font-size: 1rem;
            }
            form {
                input, select, textarea {
                    font-size: .825rem;
    
                    &::placeholder {
                        font-size: .825rem;
                    }
                }

                select {
                    height: 44px;
                }

                div.checkbox {
                    font-size: 1rem;
                }
            }
            
        }
    }
}