section.guides {
    display: none;
    margin: 0 auto;
    width: 85.7vw;

    .button {
        background-color: $ultra-light-gray;
        border: 1px solid $extra-light-gray;
        padding: 1rem 3rem;
        font-size: .75rem;
        font-family: $libre;
        font-weight: 600;
        color: $gray;

        &:hover {
            color: $white;
            background-color: $dark-gray;
        }
    }

    .purchase-link {
        font-family: $merriweather;
        font-style: italic;
        color: $medium-blue;
        text-decoration: none;
        font-size: 0.6875rem;

        &:hover { 
            color: $light-gray;
        }
    }

    &.active {
        display: block;
    }

    .guides-pageheader {
        justify-content: space-between;
        border-bottom: 1px solid $extra-light-gray;
        padding-bottom: 2.5vw;
        margin-bottom: 2.5vw;

        .cover-wrap {
            flex-basis: 25vw;
            img {
                width: 100%;
                height: auto;
            }
        }

        .guides-content-wrap {
            flex-basis: 56vw;

            .guides-heading {
                h2 {
                    display: inline-block;
                    margin: 0 0 1.5rem;
                }
                .sub {
                    display: inline-block;
                    font-family: $merriweather;
                    font-style: italic;
                    font-size: 0.6875rem;
                    color: $gray;
                }

            }
        }

        .guides-description {
            justify-content: space-between;
            flex-wrap: nowrap;
            margin-left: -4%;
            .desc {
                font-family: $libre;
                font-size: 0.8125rem;
                line-height: 1.54em;
                flex-basis: 45%; 
                padding-left: 4%;
            }
        }

        .controls {
            select {
                min-width: 9.6vh;
                margin: 2rem 1rem 0 0;

                &:nth-of-type(2) {
                    min-width: 25vh;
                }
            }
        }
    }

    .more-reading {
        .heading-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                display: inline-block;
            }
        }
        .reading-grid-wrapper {
            width: 103%;
            margin-left: -1.5%;
        }
        .reading-grid {
            .item {
                display: none;
                width: 14.167%;
                flex-direction: column;
                align-items: center;
                margin: 0 1.25% 6em;
                
                &.active {
                    display: flex;
                }


                .button {
                    width: 100%;
                    text-align: center;
                    padding: 1rem 0;
                }

                select {
                    padding: 1rem 0 1rem 10%;
                    width: 90%;
                    margin-top: 1rem;
                }

                img {
                    display: none;
                    max-height: 320px;
                    max-width: 100%;

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .guides-pageheader {
            .guides-content-wrap {
                .guides-heading {
                    h2 {
                        display: block;
                        margin: 0;
                    }
                    .sub {
                        display: block;
                        margin: 0 0 1.5rem;
                    }
    
                }

                .guides-description {
                    flex-wrap: wrap;

                    .desc {
                        flex-basis: 100%;
                        &:not(:first-of-type) {
                            margin-top: 1rem;
                        }
                    }

                }
            }
    
            .controls {
                select {
                    width: 95%;
                    padding: 1rem 0 1rem 5%;
                    margin: 2rem 1rem 0 0;
                }

                .button {
                    margin: 2rem 1rem 2rem 0;
                    width: 50%;
                    text-align: center;
                    padding: 1rem 0;
                }
            }
        }
    
        .more-reading {
            .heading-row {
                h2 {
                    font-size: 2.5rem;
                }
            }
            .reading-grid {
                .item.active {
                    display: flex;
                }
                .item {
                    width: 22.5%;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 1.25% 6rem;
    
                    .button {
                        width: 100%;
                        text-align: center;
                        padding: 1rem 0;
                    }
    
                    select {
                        padding: 1rem 0 1rem 10%;
                        width: 90%;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    @include breakpoint(mobile, max) {
        .guides-pageheader {
            .cover-wrap {
                flex-basis: 100%;
                margin-bottom: 6vw;
            }
        
            .guides-content-wrap {
                flex-basis: 100%;
            }
        }
        
    
        .more-reading {
            
            .heading-row {
                flex-wrap: wrap;
                h2 {
                    flex-basis: 100%;
                    display: block;
                    font-size: 1.5rem;
                }
            }
            .reading-grid {
                .item {
                    width: 48%;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 1% 6rem;
    
                    .button {
                        width: 100%;
                        text-align: center;
                        padding: 1rem 0;
                    }
    
                    select {
                        padding: 1rem 0 1rem 10%;
                        width: 90%;
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

}