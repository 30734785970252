section.photo-callout {
	height: 58vw;
	padding-top: 6em;
	background-color: $navy-blue;

	.callout-wrapper {
		height: 100%;
		//background-image: url('/img/photo-callout-bg.png');

		background-size: cover;
		background-position: center center;
		position: relative;

		span.icon {
			position: absolute;
			background-color: $black;
			color: $white;
			width: 150px;
			height: 150px;
			top: -75px;
			right: 13%;
			border-radius: 50%;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 4rem;
				text-align: center;
			}
		}
		.callout-inner {
			background-color: $overlay;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.callout-header {
				h2 {
					font-size: 4.25em;
					color: $white;
					text-align: center;
					margin: 0 1rem;
				}
			}
			.callout-message {
				margin: 2em auto;
				width: 37.5%;
			}
			.callout-message,
			.callout-message > p {
				color: $white;
				font-size: 1.25rem;
				text-align: center;
			}
		}
	}

	@include breakpoint(tablet, max) {
		padding-top: 3em;
		height: auto;
		.callout-wrapper {
			span.icon {
				width: 70px;
				height: 70px;
				top: -35px;
				left: 50%;
				transform: translateX(-50%);

				i {
					font-size: 2rem;
				}
			}
			.callout-inner {
				padding: 4em 0;
				.callout-header {
					h2 {
						font-size: 3.5rem;
					}
				}
				.callout-message {
					margin: 2em auto;
					width: 70%;
				}
				.callout-message,
				.callout-message > p {
					color: $white;
					font-size: 1.25rem;
					text-align: center;
				}
			}
		}
	}

	@include breakpoint(mobile, max) {
		.callout-wrapper {
			.callout-inner {
				.callout-header {
					h2 {
						font-size: 1.5rem;
					}
				}
				.callout-message {
					margin: 2em auto;
					width: 90%;
				}
				.callout-message,
				.callout-message > p {
					font-size: 1rem;
				}
			}
		}
	}
}
