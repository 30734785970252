section#system-page-content {
    font-family: $merriweather;
    width: 70%;
    margin: 0 auto;
    max-width: 1200px;
    padding: 3rem 0;
    p,a,ul,ol,span {
        font-family: $merriweather;

        line-height: 2.125rem;

        @include breakpoint(mobile, max) {
            line-height: 1.625rem;
        }
    }
    a {
        color: $red;
        text-decoration: none;
        font-style: initial;

        &:hover {
            color: $dark-red;
        }
    }
    .breadcrumbs {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $black;

        span {
            font-weight: bold;
        }
    }
    .system-image {
        display: flex;
        justify-content: center;
    }

    .return {
        margin-top: 5rem;
        text-align: center;

        &:before {
            content: "<";
        }
    }

    @include breakpoint(mobile, max) {
        width: 85%;
    }
}