section.share-block {
	background-color: $ultra-light-gray;

	h2 {
		margin: 0 0 2rem;
		padding-top: 4vw;
		text-align: center;
		font-size: 4.25rem;
	}

	.share-message {
		font-size: 1.5rem;
		text-align: center;
		width: 55%;
		margin: 0 auto;
	}

	.share-grid {
		width: 85%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 6vw 0 16rem;

		.share-img-block {
			position: relative;
			background-color: $extra-light-gray;
			height: 20vw;
			width: 20vw;
			outline: 7px solid transparent;
			transition: outline 0.2s ease;

			img {
				width: 100%;
				height: 100%;
				vertical-align: bottom;
			}

			&::after {
				content: "";
				position: absolute;
				top: 100%;
				left: 50%;
				margin-left: -25px;
				width: 0;
				height: 0;
				border-top: solid 25px transparent;
				border-left: solid 25px transparent;
				border-right: solid 25px transparent;
				transition: all 0.2s ease;
			}

			&.hover {
				outline: 7px solid black;

				&::after {
					border-top: solid 25px black;
				}
			}
			&:first-of-type {
				.share-block {
					left: -7px;
				}
			}

			&:nth-of-type(2),
			&:nth-of-type(3) {
				.share-block {
					left: 50%;
					margin-left: -361.5px;
				}
			}

			&:last-of-type {
				.share-block {
					right: -7px;
				}
			}

			.share-block {
				background-color: $white;
				display: none;
				position: absolute;
				bottom: -160px;
				padding: 0 2rem;

				&.active {
					display: flex;
				}
				.share-button {
					display: inline-block;
					padding: 0.5rem 5rem;
					margin: 2rem 1rem;
					border-radius: 5px;
					transition: background-color 0.2s ease;

					i {
						color: $white;
						font-size: 2rem;
					}

					svg {
						width: 2rem;
						height: auto;
					}

					&.tw {
						background-color: $share-light;
						&:hover {
							background-color: $tw;
						}
					}

					&.fb {
						background-color: $share-medium;
						&:hover {
							background-color: $fb;
						}
					}

					&.pin {
						background-color: $share-dark;
						&:hover {
							background-color: $pin;
						}
					}
				}
			}

			@include breakpoint(tablet, max) {
				height: 40vw;
				width: 40vw;

				&:first-of-type {
					margin-bottom: 22vw;
				}

				&:nth-of-type(odd) {
					.share-block {
						left: -7px;
						right: auto;
					}
				}

				&:nth-of-type(even) {
					.share-block {
						right: -7px;
						left: auto;
					}
				}

				&:nth-of-type(3) {
					.share-block {
						top: -160px;
						bottom: auto;
						left: 353.5px;
					}

					&.hover {
						&::after {
							bottom: 100%;
							top: auto;
							border-bottom: solid 25px black;
							border-top: 0;
						}
					}
				}

				&:nth-of-type(4) {
					.share-block {
						top: -160px;
						bottom: auto;
					}
					&.hover {
						&::after {
							bottom: 100%;
							top: auto;
							border-bottom: solid 25px black;
							border-top: 0;
						}
					}
				}

				.share-block {
					width: 85vw;
					padding: 0;
					justify-content: space-around;
				}
			}

			@include breakpoint(mobile, max) {
				&:first-of-type {
					margin-bottom: 172px;
					.share-block {
						bottom: -127px;
					}
				}

				&:nth-of-type(2) {
					.share-block {
						bottom: -127px;
					}
				}

				&:nth-of-type(3) {
					.share-block {
						top: -127px;
					}

					&.hover {
						&::after {
							bottom: 100%;
							top: auto;
							border-bottom: solid 25px black;
							border-top: 0;
						}
					}
				}
				&:nth-of-type(4) {
					.share-block {
						top: -127px;
					}

					&.hover {
						&::after {
							bottom: 100%;
							top: auto;
							border-bottom: solid 25px black;
							border-top: 0;
						}
					}
				}

				.share-block {
					width: 85vw;
					padding: 0;
					justify-content: space-around;

					.share-button {
						padding: 0.5rem 0;
						width: 25%;
						text-align: center;
						margin: 1rem 0;

						i {
							font-size: 1rem;
						}
					}
				}
			}
		}
	}

	@include breakpoint(tablet, max) {
		h2 {
			font-size: 3.5rem;
		}

		.share-message {
			font-size: 1rem;
			width: 85%;
		}
		.share-grid {
			padding: 2.5rem 0;
		}
	}

	@include breakpoint(mobile, max) {
		h2 {
			font-size: 1.5rem;
		}
	}
}
