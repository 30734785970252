section.body-block {
    font-family: $merriweather;
    width: 70%;
    margin: 0 auto;
    max-width: 1200px;
    padding: 3rem 0;
    
    h1 {
        font-family: Merriweather,serif;
        font-size: 3.5em;
        color: #2b2b2a;
        margin: 1rem 0;
    }

    h2 {
        font-size: 2.25em;
        margin: 1rem 0;
    }

    pre {
        white-space: pre-wrap;
        background-color: $ultra-light-gray;
        padding: 1.25rem;
    }

    blockquote {
        border-left: 4px solid $yellow;
        padding: .5rem 0 .5rem 1rem;
    }
    .full-body, .flex-block {
        line-height: 1.75;
        p,a,ul,ol {
            font-family: $merriweather;
            line-height: 2.125rem;

            @include breakpoint(mobile, max) {
                line-height: 1.625rem;
            }
        }
        a {
            color: $red;
            text-decoration: none;
            font-style: initial;
            &:hover {
                color: $dark-red;
            }
        }
    }

    .flex {
        justify-content: space-between;
        .flex-block {
            flex-basis: 48%;
            margin-bottom: 2rem;
        }
    }

    @include breakpoint(tablet, max) {
        h1 {
            font-size: 2.75em;
        }
        h2 {
            font-size: 1.75em;
        }
    }
    @include breakpoint(mobile, max) {
        width: 85%;

        h1 {
            font-size: 1.75em;
        }
        h2 {
            font-size: 1.25em;
        }

        .flex {
            .flex-block {
                flex-basis: 100%;
            }
        }
    }
}