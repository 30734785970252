.sort-menu {

  select {
    width: 11vw;

    &:first-of-type {
        margin-right: 1rem;
    }
  }
}

@include breakpoint(mobile, max) {
  .sort-menu {
    flex-basis: 100%;

    select {
      width: 95%;
      padding: 1rem 0 1rem 5%;
      margin-bottom: 1.5rem;
      
      &:first-of-type {
        margin-right: 0;
      }
    } 
  }
}