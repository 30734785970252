section.system-selector {
    .selection-wrapper {
        width: 85.7vw;
        margin: 5rem auto;
        justify-content: space-between;
        
        .content-block {
            width: 23.4vw;
            border: 1px solid $light-gray;
            padding: 1.7vw;
            position: relative;
            cursor: pointer;
            transition: all .2s ease;

            .header {
                font-family: $libre;
                font-size: 2.125rem;
                font-weight: 900;
                transition: all .2s ease;
            }

            .message {
                font-family: $merriweather;
                font-size: .75rem;
                font-style: italic;
                transition: all .2s ease;
            }

            &:hover, &.active {
                background-color: $black;
                border-color: $black;
                .header, .message {
                    color: $white;
                }
            }

            &.active {
                @include breakpoint(tablet, min) {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -25px;
                        width: 0;
                        height: 0;
                        border-top: solid 25px $black;
                        border-left: solid 25px transparent;
                        border-right: solid 25px transparent;
    
                    }
                }
                
            }

            
        }

        @include breakpoint(tablet, max) {
            margin: 6vw auto;

           .content-block {
            width: 100%;
            margin: 1vw 0;

            .header {
                font-size: 1.5rem;
            }
           }
        }
    }
    
}