section.twitter-block {
    
    padding: 6em 0 4em;

    .tweets {
        position: relative;
        border: 2px solid $light-gray;
        .fa-twitter {
            position: absolute;
            background-color: $black;
            color: $white;
            font-size: 75px;
            padding: 37.5px;
            top: -75px;
            left: -75px;
            border-radius: 100%;
            z-index: 5;
        }
        width: 70%;
        margin: 0 auto;
        background-color: $white;
        border-radius: 10px;
        .tweet-wrapper {
            border-bottom: 2px solid $light-gray;
            .slick-list {
                .slick-track {
                    display: flex;
                    .slick-slide {
                        display: flex;
                        height: auto;

                        &>div {
                            width: 100%;
                            .tweet-slide {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .tweet-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                font-size: 1.125rem;
    
                .user {
                    display: flex;
                    align-items: center;
                    padding: 3em 0;
                    .userphoto {
                        border-radius: 100%;
                        height: 62px;
                        width: 62px;
                        float: left;
                    }
                    .user-details {
                        padding-left: 1em;
                        .name { 
                            font-size: 1.125em
                        }
                        .username {
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }
    
                .tweet {
                    width: 50%;
                    text-align: center;
                    font-size: 1.25em;
                }
    
                .tweet-footer {
                    padding: 6em 0 1em;
                    width: 90%;
                    margin-top: auto;
                    justify-content: space-between;
                    .controls {
                        a {
                            margin-right: 1em;
                            &.retweet:hover {
                                color: $tw;
                            }
                            &.like:hover {
                                color: $yt;
                            }
                        }
                    }
                    div, div>a {
                        color: $light-gray;
                        text-decoration: none;

                        &:hover {
                            color: $medium-gray;
                        }
                    }
                }
            }
        }

        .slide-controls {
            height: 25px;
            
            .dots-container {
                position: relative;
                display: flex;
                justify-content: center;
                width: 290px;
                margin: 0 auto;

                .slick-next {
                    right: 0;
                }
                .slick-prev {
                    left: 0;
                }
                .slick-next:before, .slick-prev:before {
                    color: $red;
                    font-family: "Font Awesome 5 Pro";
                }

                .slick-dots {
                    bottom: -20px;
                    width: auto;
                    display: inline-block;
                    margin: 0 auto;
                }
                .slick-dots li button:before {
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    line-height: 0;
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        padding: 3em 0 4em;
        .tweets {
            width: 90%;

            i.fa-twitter {
                left: 50%;
                margin-left: -35px;
                font-size: 30px;
                padding: 20px;
                top: -35px;
            }

        }
    }

    @include breakpoint(mobile, max) {
        .tweets {
            .tweet-wrapper {
                .tweet-block {
                    font-size: 1rem;
                    .user {
                        &-details {
                            .name {
                                font-size: 1rem;
                            }
                            .username {
                                font-size: .875rem;
                            }
                        }
                    }
                    .tweet {
                        width: 90%;
                        font-size: 1em;
                    }
                    .tweet-footer {
                        div, div>a {
                            font-size: 1rem;
                        }
                    }
                }
            }

        }
    }
    
}