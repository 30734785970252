section.button-callout {
    background-color: $gray-blue;
    position: relative;

    .float-circle {
        position: absolute;

        &.left {
            left: 12%;
            bottom: 50%;
            width: 87px;
            height: 87px;
        }

        &.right {
            right: 15%;
            bottom: 60%;
            width: 52px;
            height: 52px;

            &.top {
                width: 37px;
                height: 37px;
                right: 7%;
                top: -100%;
            }
        }

        @include breakpoint(tablet, max) {
            display: none;
        }
    }
    .callout-inner {
        padding: 0  0 2em 0;
        width: 35%;
        margin: 0 auto;

        .callout-message {
            color: $white;
            text-align: center;
            font-size: 1.25rem;
        }
        @include breakpoint(tablet, max) {
            width: 60%;
        }

        @include breakpoint(mobile, max) {
            width: 100%;

            .callout-message {
                width: 90%;
                margin: 0 auto;
                font-size: 1rem;
            }
        }
    }
}